import { useState } from "react";
import { Dayjs } from "dayjs";

interface UseFiltersResult {
  selection: string;
  selectedItems: string[];
  displayItems: string[];
  selectedDate: Dayjs | null;
  resetFilters: () => void;
  setSelection: React.Dispatch<React.SetStateAction<string>>;
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
  setDisplayItems: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
}

const useFilters = (): UseFiltersResult => {
  const [selection, setSelection] = useState<string>("month");
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [displayItems, setDisplayItems] = useState<string[]>([]);

  const resetFilters = () => {
    setSelection("month");
    setSelectedDate(null);
    setSelectedItems([]);
    setDisplayItems([]);
  };

  return {
    selection,
    selectedDate,
    selectedItems,
    displayItems,
    resetFilters,
    setSelection,
    setSelectedDate,
    setSelectedItems,
    setDisplayItems,
  };
};

export default useFilters;
