import axios from "axios";
import { toast } from "react-toastify";
import store from "../redux/store"; // Import your store
import { clearTokens, setTokens } from "../redux/authSlice"; // Import the clearTokens action
const BACKENDBASEURL = process.env.REACT_APP_API_BASE_URL


const apiClient = axios.create({
  baseURL: BACKENDBASEURL, 
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor to add the JWT token to the Authorization header
apiClient.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const accessToken = state.auth.accessToken;

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle token expiration (optional) and show toast notifications
apiClient.interceptors.response.use(
  (response) => {
    // Handle successful responses
    if (
      (response.status === 200 || response.status === 201) &&
      response.data.message
    ) {
      toast.success(response.data.message || "Request successful!");
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const status = error.response?.status;

    // Handle token expiration (401) and refresh token logic
    if (status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Ensure it's not retried more than once
      const state = store.getState();
      const refreshToken = state.auth.refreshToken;

      if (refreshToken) {
        try {
          const { data } = await axios.post(BACKENDBASEURL +"/api/token/refresh/", {
            refresh: refreshToken,
          });

          store.dispatch(
            setTokens({
              accessToken: data.access,
              refreshToken,
              user: {
                name: state.auth.user?.name || "",
                email: state.auth.user?.email || "",
                isActive: state.auth.user?.isActive || false,
              },
            })
          );

          // Retry original request with new access token
          originalRequest.headers.Authorization = `Bearer ${data.access}`;
          return apiClient(originalRequest);
        } catch (err) {

          store.dispatch(clearTokens()); // Clear tokens in Redux
          window.location.href = "/login"; // Redirect to login
          return Promise.reject(err); // Stop further retries
        }
      } else {
        store.dispatch(clearTokens());
        window.location.href = "/login"; // Redirect to login
      }
    }

    // Show error toast based on status code
    switch (status) {
      case 400:
        toast.error(error.response.data.message || "Bad request!");
        break;
      case 401:
        toast.error("Unauthorized! Please login again.");
        break;
      case 403:
        toast.warning("You do not have permission to perform this action.");
        break;
      case 404:
        toast.error("Resource not found.");
        break;
      case 500:
        toast.error("Internal server error. Please try again later.");
        break;
      default:
        toast.error(error.response.data.message || "An error occurred.");
        break;
    }

    return Promise.reject(error);
  }
);

export default apiClient;
