import axios from "axios";
import { useState } from "react";

const PmsSelection = () => {
    const [selectedPms, setSelectedPms] = useState('');
    const [apiKey, setApiKey] = useState('');
  
    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      try {
        await axios.post('/api/connect-pms', { selectedPms, apiKey });
      } catch (error) {
        console.error(error);
      }
    };
  
    return (
      <form onSubmit={handleSubmit}>
        <select onChange={(e) => setSelectedPms(e.target.value)}>
          <option value="pms1">PMS 1</option>
          <option value="pms2">PMS 2</option>
        </select>
        <input value={apiKey} onChange={(e) => setApiKey(e.target.value)} placeholder="API Key" />
        <button type="submit">Connect</button>
      </form>
    );
  };
  
export default PmsSelection