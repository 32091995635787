import type { ColumnsType } from "antd/es/table";
import { Property } from "./types";
import { CONVERT_CURRENCY_CODE } from "../utils";
import { Typography } from "@mui/material";
import NumberConverter from "../../../utils/NumberFormatter";



export const PORTFOLIO_COLUMNS: ColumnsType<Property> = [
    {
      title: "Units",
      dataIndex: "unit",
      width: 200,
      key: "unit",
      fixed: "left",
      render: (text, record) => {
      const is_total =
        text === "Target" ||
        text === "Revenue" ||
        text === "Achievement Rate (%)";
      return (
        <div className={`${is_total ? "text-blue-500" : ""}`}>
          {text}
        </div>
      );
    },
    },
  ];

 export const PORTFOLIO_TOTALCOLUMN = (varianceSelection: string): ColumnsType<Property> => [
    {
      title: "Total",
      width: 100,
      align: "center",
      key: "total",
      fixed: "right",
      render: (_, record) => {
        const isPercent = varianceSelection === "%";
  
        const comparison = isPercent
          ? record?.total_comparison_percent?.net_comparison_percent
          : record?.total_comparison_value?.net_comparison_value;
  
        return (
          <div className="flex flex-col items-center">
            <div>
              {CONVERT_CURRENCY_CODE(record?.currency)}{" "}
              <NumberConverter value={record.total_net_revenue} />
            </div>
            {comparison && comparison !== "N/A" && (
              <div
                className={`font-semibold ${
                  (isPercent && comparison.includes("-")) ||
                  (!isPercent && parseFloat(comparison) < 0)
                    ? "text-red-500"
                    : "text-green-500"
                }`}
              >
                <Typography variant="caption">
                  {!isPercent ? CONVERT_CURRENCY_CODE(record?.currency) : ""}{" "}
                  <NumberConverter value={comparison} />
                  {isPercent || !record?.total_net_revenue ? "%" : ""}
                </Typography>
              </div>
            )}
          </div>
        );
      },
    },
  ];