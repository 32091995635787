import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import ScheduleDemo from "./scheduleDemo";

const Home = () => {
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);
  const navigate = useNavigate();
  const formSectionRef = useRef<HTMLDivElement>(null);

  const scrollToForm = () => {
    formSectionRef.current?.scrollIntoView({ behavior: "smooth" }); // Smooth scrolling
  };

  return (
    <>
      <div className="w-full mx-auto h-full">
        <div className="fixed top-0 z-50 bg-white shadow-md min-w-full inline-flex px-6">
          <div className="place-content-start inline-flex w-full">
            <div>
              <img
                src="vividity main.png"
                height={200}
                width={180}
                className="pt-2"
              ></img>
            </div>
          </div>
          <div className="flex items-center justify-between px-4 py-2">
            {/* Left Side Navigation */}
            <div className="flex items-center space-x-6">
              <Link
                to="#home-section"
                className="text-gray-700 font-semibold hover:text-blue-700 py-1 text-nowrap"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default link behavior
                  const section = document.getElementById("home-section");
                  section?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Home
              </Link>
              <Link
                to="#about-section"
                className="text-gray-700 font-semibold hover:text-blue-700 py-1 text-nowrap"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default link behavior
                  const section = document.getElementById("about-section");
                  section?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                About Us
              </Link>
              <Link
                to="#contact-section"
                className="text-gray-700 font-semibold hover:text-blue-700 py-1 text-nowrap pr-5"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default link behavior
                  const section = document.getElementById("contact-section");
                  section?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Contact Us
              </Link>
            </div>

            {/* Right Side Button */}
            <div className="text-white rounded-lg font-bold my-auto px-2 py-2">
              {accessToken ? (
                <Button
                  onClick={() => navigate("/dashboard/home")}
                  className="!text-white px-3 text-nowrap !font-semibold !mr-6"
                  style={{ backgroundColor: "#0066b2" }}
                >
                  Go to Dashboard
                </Button>
              ) : (
                <Button
                  onClick={() => navigate("/login")}
                  className="!text-white px-3 text-nowrap !font-semibold !mr-6"
                  style={{ backgroundColor: "#0066b2" }}
                >
                  Login
                </Button>
              )}
            </div>
          </div>
        </div>
        <div>
          {/*                first view                        */}

          <div  id="home-section" className="grid grid-cols-2 gap-4 w-3/4 mx-auto h-screen items-center">
            <div className="text-center px-5 pt-10">
              <h1 className="text-4xl pb-5 font-medium text-left">
                Maximize Your{" "}
                <span className="" style={{ color: "#0066b2" }}>
                  <b>Revenue </b>
                </span>{" "}
                Potential with Our Innovative Solution
              </h1>
              <p className="text-lg font-thin text-left">
                Empower your business with intelligent pricing, real-time data,
                and comprehensive reporting. Vividity helps you make data-driven
                decisions that maximize your revenue and streamline operations.
              </p>
              <div className="flex pl-4 inline-flex text-white mt-8 py-2 font-bold mx-0 w-full justify-start">
                <Button
                  onClick={scrollToForm}
                  className="!text-white px-3 text-nowrap !font-semibold !capitalize"
                  style={{ backgroundColor: "#0066b2" }}
                >
                  Schedule a demo
                </Button>
                <Link
                  type="submit"
                  color="inherit"
                  className="px-5 py-3 bg-blue-100 rounded-lg shadow-lg ml-3 text-blue-800 border"
                  to={""}
                >
                  Documentation
                </Link>
              </div>
            </div>
            <div className="">
              <img
                src="due-deligenace-banner (1).png"
                width={600}
                height={600}
              ></img>
            </div>
          </div>

          {/*                   about section              */}

          {/* <div id="about-section" className="my-10 w-3/4 mx-auto py-20">
            <h2>About Us</h2>
            <p>This is the About Us section of the page.</p>
          </div> */}

          {/*                 contact section                      */}

          <div ref={formSectionRef} id="contact-section" className="py-10">
            <ScheduleDemo />
          </div>
        </div>
      </div>

      {/* <div
        className="flex inline-flex bg-white text-blue-800 text-sm fixed w-full gap-4 px-2 justify-between items-center"
        style={{ bottom: "0" }}
      >
        <div
          color="inherit"
          className="border-white align-left w-fit font-medium tracking-wider cursor-pointer"
          style={{ fontSize: 12 }}
        >
          vividity.com
        </div>
        <div
          className="text-xs border-slate-50 p-2 text-blue-800 !font-smeibold"
          style={{ fontSize: 10, whiteSpace: "nowrap" }}
        >
          Copyright © Keleno Labs Pvt. Ltd. All rights reserved.
        </div>
      </div> */}
    </>
  );
};

export default Home;
