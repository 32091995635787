import React from "react";
import { Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";

const NotFound: React.FC = () => (
  <div className="flex flex-cols-2 items-center justify-center w-screen h-screen overflow-hidden">
    <div className="rounded-lg bg-blue-500 h-screen w-screen">
      <div className="grid items-center content-center w-full h-screen">
        <Typography
          variant="body1"
          className="text-white !font-medium !text-2xl text-center"
        >
          Sorry, the page you are looking for does not exist.
        </Typography>
        <div className="rounded-lg shadow-lg m-auto px-3 py-2 w-fit mt-2 text-blue-500 bg-white font-semibold">
          <Link to="/" type="submit" className="">
            Go to home page
          </Link>
        </div>
      </div>
    </div>
    <img src="404-not-found.jpg" width={500} height={500}></img>
  </div>
);

export default NotFound;
