import { useCallback, useEffect, useState } from "react";
import ToggleButton from "../../../components/ToggleButton";
import CustomMatrix from "./CustomMatrix";
import { VARIANCETYPES } from "./utils";
import { Container, Typography } from "@mui/material";
import { DatePicker, Input } from "antd";
import ReusablePagination from "../../../components/PaginationComponent";
import dayjs from "dayjs";
import useSearchPagination from "../../../hooks/useSearchPaginationFetch";
import { fetchRevenue } from "../../../services/portfolioAPI";
import useDebounce from "../../../hooks/useDebounce";
import PageHeader from "../../../components/PageHeader";

const Targets: React.FC = () => {
  const { Search } = Input;
  const [data, setData] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [varianceSelection, setVarianceSelection] = useState<string>("%");
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [currentYear, setCurrentYear] = useState<number>(dayjs().year());
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const itemsPerPage = 20;
  const {
    currentPageNumber,
    setCurrentPageNumber,
    totalItems,
    currentPageSize,
    setCurrentPageSize,
    setIsLoading,
    isLoading,
    setTotalItems,
  } = useSearchPagination("", itemsPerPage);

  const fetchRev = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await fetchRevenue(
        currentPageNumber,
        currentPageSize,
        currentYear,
        debouncedSearchValue
      );
      const res_total = response?.data?.results?.monthly_totals || [];
      const res_units = response?.data?.results?.results || [];
      setData([...res_total, ...res_units]);
      setTotalItems(response?.data?.count);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [
    currentPageNumber,
    currentPageSize,
    currentYear,
    setData,
    setTotalItems,
    setIsLoading,
    debouncedSearchValue,
  ]);

  useEffect(() => {
    if (!isInitialLoad) {
      setData([]);
      setTotalItems(0);
      setCurrentPageNumber(1);
      fetchRev();
    } else {
      setIsInitialLoad(false);
    }
  }, [currentPageSize, currentYear, debouncedSearchValue]);

  useEffect(() => {
    setData([]);
    setTotalItems(0);
    fetchRev();
  }, [currentPageNumber]);

  const filteredData = searchValue ? data.slice(3) : data;

  return (
    <Container className="p-4 flex flex-col space-y-4 bg-gray-100 min-h-screen min-w-full !mx-0 overflow-y-hidden">
      <div className="flex w-full justify-start gap-4 items-center">
        <PageHeader text="Targets" />
      </div>
      <div className="flex inline-flex w-full justify-between space-x-4 items-center bg-white px-5 py-3 mt-3 rounded-lg shadow-lg">
        <div className="flex justify-start gap-4 w-1/2">
          <DatePicker
            picker="year"
            value={dayjs(`${currentYear}`, "YYYY")}
            onChange={(date) => {
              if (date) {
                setCurrentYear(date.year());
              }
            }}
            allowClear={false}
            className="w-1/8"
          />
          <Search
            placeholder="Search Units"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            className="w-1/2"
          />
        </div>
        <div className="flex justify-end gap-4 w-1/2 h-8">
          <ToggleButton
            selection={varianceSelection}
            setSelection={setVarianceSelection}
            Choices={VARIANCETYPES}
          />
        </div>
      </div>
      <div className="relative">
        <div className="rounded-lg shadow-lg">
          <CustomMatrix
            data={filteredData}
            year={currentYear}
            varianceSelection={varianceSelection}
            isLoading={isLoading}
          />
          <ReusablePagination
            currentPage={currentPageNumber}
            onPageSizeChange={setCurrentPageSize}
            pageSize={currentPageSize}
            totalItems={totalItems}
            onPageChange={setCurrentPageNumber}
          />
        </div>
      </div>
    </Container>
  );
};

export default Targets;
