import React, { useEffect, useState } from "react";
import { InputNumber, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Typography } from "@mui/material";
import { FileExcelOutlined } from "@ant-design/icons";
import MonthPicker from "../../../components/MonthPicker";
import dayjs, { Dayjs } from "dayjs";
import { MONTHS } from "./constants";
import { fetchPropertyTarget } from "../../../services/targetAPI";
import ExcelTransformModal from "./ExcelTranformModal";
import { PropertyChanges, ResponseData, MonthlyData } from "./types";

const TargetMonthlyTable: React.FC<{
  propertyID: string | number;
  isEditing: boolean;
  isExpanded: boolean;
  handleTargetEdit: (
    id: string | number,
    month: string,
    value: string | number | null
  ) => void;
  targetTableChanges: any;
}> = ({
  propertyID,
  isEditing,
  handleTargetEdit,
  isExpanded,
  targetTableChanges,
}) => {
  const currentYear = dayjs().year();

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    dayjs().year(currentYear)
  );
  const [data, setData] = useState<any>([]);
  const [currency, setCurrency] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editedData, setEditedData] = useState<Record<string, number> | null>(
    {}
  );

  const fetchTarget = async () => {
    setIsLoading(true);
    setData([]);
    let startYear = dayjs()
      .year(currentYear)
      .startOf("year")
      .format("YYYY-MM-DD");
    let endYear = dayjs().year(currentYear).endOf("year").format("YYYY-MM-DD");
    if (selectedDate) {
      startYear = selectedDate.startOf("year").format("YYYY-MM");
      endYear = selectedDate.endOf("year").format("YYYY-MM");
    }
    try {
      const response = await fetchPropertyTarget({
        propertyID,
        params: {
          start_month: startYear,
          end_month: endYear,
        },
      });

      const transformedData = response?.data?.result?.reduce(
        (
          acc: any,
          { month, target_amount }: { month: string; target_amount: string }
        ) => {
          const monthName = MONTHS[parseInt(month.split("-")[1], 10) - 1];
          acc[monthName] = parseFloat(target_amount);
          return acc;
        },
        {}
      );

      const responseData: ResponseData[] = response?.data?.result || [];
      const propertyChanges: PropertyChanges =
        targetTableChanges[propertyID] || {};

      const dataMap = new Map<string, number>(
        responseData.map(({ month, target_amount }) => [
          month,
          parseFloat(target_amount),
        ])
      );

      Object.entries(propertyChanges).forEach(([monthYear, targetAmount]) => {
        const [month, year] = monthYear.split("-");
        const paddedMonth = String(
          new Date(`${month} 1`).getMonth() + 1
        ).padStart(2, "0");
        if (parseInt(year) === selectedDate?.year()) {
          const formattedDate = `${year}-${paddedMonth}`;
          dataMap.set(formattedDate, targetAmount);
        }
      });

      const transformedEditData = Array.from(dataMap.entries()).reduce<
        Record<string, number>
      >((acc, [month, target_amount]) => {
        const monthName = MONTHS[parseInt(month.split("-")[1], 10) - 1];
        acc[monthName] = target_amount;
        return acc;
      }, {});


      setData([transformedData]);
      setEditedData(transformedEditData);
      setCurrency(response?.data?.currency);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    isExpanded && selectedDate && fetchTarget();
  }, [selectedDate, isExpanded, isEditing]);

  const handleInputChange = (month: string, value: number | null) => {
    setEditedData((prev: any) => ({
      ...prev,
      [month]: value,
    }));
    handleTargetEdit(propertyID, `${month}-${selectedDate?.year()}`, value);
  };

  const handleApplyChanges = (updatedData: Record<string, number> | null) => {
    setEditedData(updatedData);
    updatedData &&
      Object.keys(updatedData).forEach((month) => {
        handleTargetEdit(
          propertyID,
          `${month}-${selectedDate?.year()}`,
          updatedData[month]
        );
      });
    setIsModalVisible(false);
  };

  const columns: ColumnsType<MonthlyData> = MONTHS.map((month:string) => ({
    title: month,
    dataIndex: month,
    key: month,
    width: "8.33%",
    align: "center",
    render: (text: any) =>
      isEditing ? (
        <InputNumber
          controls={false}
          value={editedData && editedData[month] ? editedData[month] : null}
          style={{ width: "60px", padding: 0 }}
          min={0}
          onChange={(value) => handleInputChange(month, value)}
        />
      ) : (
        text
      ),
  }));

  return (
    <div>
      <div className="w-full flex flex-cols-2 justify-between pb-4 items-center">
        <Typography variant="subtitle2" className="!text-black ">
          Target Details{" "}
          {currency && currency?.length > 0 ? `(${currency})` : ""}
        </Typography>
        <div className="!w-fit flex items-center gap-4">
          <MonthPicker
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            isMonthly={false}
            allowClear={false}
          />
          {isEditing ? (
            <FileExcelOutlined
              style={{ fontSize: "20px", cursor: "pointer", color: "#28a745" }}
              onClick={() => setIsModalVisible(true)}
            />
          ) : (
            ""
          )}
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={data}
        rowKey="month"
        bordered
        pagination={false}
        className="border-t-2"
        scroll={{ x: "calc(980px)" }}
        loading={isLoading}
      />

      <ExcelTransformModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        handleApplyChanges={handleApplyChanges}
        initialData={editedData}
      />
    </div>
  );
};

export default TargetMonthlyTable;
