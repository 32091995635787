import { AppBar, Toolbar, Button, Menu, MenuItem, Fade } from "@mui/material";
import { NAVIGATION } from "./utils";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../src/assets/media/vividity main.png";
import { useState } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import CustomMenu from "./CustomMenu";
import { Account } from "@toolpad/core";

export function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [hovered, setHovered] = useState(false); // To manage hover state

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setHovered(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setHovered(false);
  };

  return (
    <>
      <AppBar
        position="static"
        className="!bg-white !shadow-none !fixed !h-16 !top-0" style={{ zIndex: 999 }}>
        <Toolbar className="justify-between">
          <img src={logo} height={50} width={150} className="pt-2" alt="Logo" />
          <div className="inline-flex justify-end">
            <div className="inline-flex pr-6">
              {NAVIGATION.map((item: any) => {
                const isSelected = location.pathname === `/${item.segment}`;
                return (
                  <div key={item.segment} className="relative !cursor-pointer	">
                    <Button
                      onClick={() => navigate(`/${item.segment}`)}
                      className={`!font-semibold !capitalize !cursor-pointer ${isSelected ? "!text-blue-700" : "!text-gray-500"}`}
                      style={{
                        marginRight: 10,
                        borderRadius: "8px",
                      }}
                      onMouseEnter={
                        item.title === "Reports"
                          ? handleMenuOpen
                          : handleMenuClose
                      }
                    >
                      {item.title}
                      {item.title === "Reports" && (
                        <KeyboardArrowDown className="ml-1" />
                      )}
                    </Button>

                    {item.title === "Reports" && (
                      <Menu
                        id="fade-menu"
                        MenuListProps={{
                          "aria-labelledby": "fade-button",
                          onMouseEnter: () => setHovered(true),
                          onMouseLeave: handleMenuClose,
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                        TransitionComponent={Fade}
                        className="!my-0"
                        style={{ zIndex: 999 }}
                      >
                        <MenuItem
                          onClick={() => navigate("/dashboard/reports/targets")}
                          className="!bg-white rounded !text-gray-500 !font-semibold !py-0" style={{ fontSize: 14 }}
                        >
                          Targets
                        </MenuItem>
                      </Menu>
                    )}
                  </div>
                );
              })}
            </div>
            <div>
              <Account
                slots={{
                  menuItems: CustomMenu,
                }}
                className="!ml-auto w-fit"
                style={{ marginLeft: "auto" }}
              />
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}
