import React, { useMemo } from "react";
import { Table } from "antd";
import { createStyles } from "antd-style";

import { HOME_BOOKING_SOURCE_TABLE } from "../constants";


import { Property, BookingSourceTableProps } from "../types";



const useStyle = createStyles(({ css }) => {
  return {
    customTable: css`
      .ant-table-container {
        .ant-table-body,
        .ant-table-content {
          scrollbar-width: thin;
          scrollbar-color: unset;
        }
      }
    `,
  };
});

const BookingSourceTable: React.FC<BookingSourceTableProps> = ({
  data,
  revenueSelection = "net",
}) => {
  const { styles } = useStyle();

  const columns = HOME_BOOKING_SOURCE_TABLE(revenueSelection as "net" | "gross");

  return (
    <div className="h-64 overflow-hidden overflow-y-auto mt-2 px-0 rounded-lg">
      <Table<Property>
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        className={`font-semibold h-fit mt-3 rounded-lg ${styles.customTable}`}
      />
    </div>
  );
};

export default BookingSourceTable;
