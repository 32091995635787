import React, { ReactElement, JSXElementConstructor, useEffect } from "react";
import {
  Container,
  Typography,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import dayjs from "dayjs";
import ToggleButton from "../../../components/ToggleButton";
import { CHARTCHOICES } from "../utils";

import { shortMonths } from "./constants";

const ChartCard = ({
  title,
  value,
  children,
  selection,
  setSelection,
  selectionMonth,
  setSelectionMonth,
  isFilter = false,
  isDatePicker = false,
  selectedYear = 2024,
}: {
  title: string;
  value: string | number;
  selection?: string;
  selectionMonth?: string;
  isFilter?: boolean;
  isDatePicker?: boolean;
  selectedYear?: number;
  setSelection?: React.Dispatch<React.SetStateAction<string>>;
  setSelectionMonth?: React.Dispatch<React.SetStateAction<string>>;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
}) => {
  const handleMonthChange = (event: SelectChangeEvent<string>) => {
    const selectedMonth = event.target.value;
    setSelectionMonth?.(selectedMonth);
  };

  useEffect(() => {
    if (!selectionMonth && setSelectionMonth) {
      const currentMonth = dayjs().format("MMM");

      setSelectionMonth(`${currentMonth} ${selectedYear}`);
    }
  }, [setSelectionMonth, selectedYear]);

  return (
    <Container
      className="bg-white rounded-lg shadow-lg flex flex-col items-start justify-start p-2 h-80 !px-0 !w-full"
      maxWidth="sm"
    >
      <div className="flex justify-between w-full">
        <Typography variant="h6" className="!font-semibold !text-xs p-1 px-4">
          {title}
        </Typography>
        {isFilter && selection && setSelection ? (
          <ToggleButton
            selection={selection}
            setSelection={setSelection}
            Choices={CHARTCHOICES}
            className="mr-4"
          />
        ) : (
          ""
        )}
        {isDatePicker && selectionMonth && setSelectionMonth ? (
          <Select
            value={selectionMonth}
            onChange={handleMonthChange}
            className="p-1 h-6 !mr-4"
            variant="outlined"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200, // Adjust height as needed
                },
              },
            }}
          >
            {shortMonths.map((month, index) => (
              <MenuItem key={index} value={`${month} ${selectedYear}`}>
                {`${month} ${selectedYear}`}
              </MenuItem>
            ))}
          </Select>
        ) : (
          ""
        )}
      </div>
      <Typography variant="h6" className="!font-semibold !text-lg pl-1 !px-4">
        {value}
      </Typography>
      <div className="h-full w-full mt-1">{children}</div>
    </Container>
  );
};

export default ChartCard;
