import React, { useState } from "react";
import { DatePicker } from "antd";
import "tailwindcss/tailwind.css";
import dayjs, { Dayjs } from "dayjs";

interface MonthPickerProps {
  selectedDate?: Dayjs | null;
  defaultValue?: Dayjs | null;
  setSelectedDate?: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  isMonthly: boolean;
  isDate?: boolean;
  onSelect?: any;
  allowClear?: boolean;
}

const MonthPicker: React.FC<MonthPickerProps> = ({
  selectedDate,
  defaultValue = null,
  setSelectedDate,
  isMonthly,
  isDate = false,
  onSelect,
  allowClear = true,
}) => {
  const [selectDate, setSelectDate] = useState<Dayjs | null>(defaultValue);
  let formatValues: "MMM YYYY" | "YYYY" | "DD-MM-YYYY" | undefined;
  let picker: "date" | "month" | "year" | undefined;
  let placeHolderText:
    | "Select Month"
    | "Select Date"
    | "Select Year"
    | undefined;
  if (isDate) {
    picker = "date";
    placeHolderText = "Select Date";
    formatValues = "DD-MM-YYYY";
  } else if (isMonthly) {
    picker = "month";
    placeHolderText = "Select Month";
    formatValues = "MMM YYYY";
  } else {
    picker = "year";
    placeHolderText = "Select Year";
    formatValues = "YYYY";
  }
  // Handle date change
  const onChange = (date: Dayjs | null) => {
    setSelectedDate && setSelectedDate(date);
    onSelect && onSelect(date);
    defaultValue && setSelectDate(date);
  };

  return (
    <div className="w-full">
      <DatePicker
        className="bg-white w-full"
        placeholder={placeHolderText}
        value={defaultValue ? selectDate : selectedDate}
        onChange={onChange}
        format={formatValues}
        allowClear={allowClear}
        picker={picker}
      />
    </div>
  );
};

export default MonthPicker;
