import HomeIcon from "@mui/icons-material/Home";
import DescriptionIcon from "@mui/icons-material/Description";
import HotelIcon from '@mui/icons-material/Hotel';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SpeedIcon from '@mui/icons-material/Speed';

import type { Navigation } from "@toolpad/core";

export const NAVIGATION: Navigation = [
  {
    segment: "dashboard/home",
    title: "Dashboard",
    icon: <HomeIcon />,
  },
  {
    segment: "dashboard/performance",
    title: "Performance",
    icon: <SpeedIcon />,
  },
  {
    segment: "dashboard/bookings",
    title: "Bookings",
    icon: <HotelIcon />,
  },
  {
    segment: "dashboard/listings",
    title: "Listings",
    icon: <ApartmentIcon />,
  },
  {
    segment: "dashboard/reports/targets",
    title: "Reports",
    icon: <DescriptionIcon />,
  },
  

];

export const COUNTRIES: Record<string, string[]> = {
  USA: ["California", "Texas", "New York"],
  Canada: ["Ontario", "Quebec", "British Columbia"],
  Mexico: ["Jalisco", "Yucatan", "Puebla"],
};

export const CHOICES = [
  {
    label: "Monthly",
    value: "month",
  },
  {
    label: "Yearly",
    value: "year",
  },
];

export const CHARTCHOICES = [
  {
    label: "Chart",
    value: "chart",
  },
  {
    label: "Tabular",
    value: "table",
  },
];

export const ITEMS_PER_PAGE = 10;

export const CURRENCY_CODE: { [x: string]: string } = {
  USD: "$",
  EUR: "€",
  inr: "₹",
};

export const CONVERT_CURRENCY_CODE = (curr: string) =>
  CURRENCY_CODE[curr?.toUpperCase()]
    ? CURRENCY_CODE[curr?.toUpperCase()]
    : curr?.toUpperCase();

export const formatNumberWithCommas = (input: number | string): string | number => {
  const number = typeof input === "string" ? parseFloat(input) : input;

  if (isNaN(number)) {
    return input
  }

  return number?.toLocaleString('en-US');
};
