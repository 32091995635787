import { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { formatNumberWithCommas } from "../../utils";

import { AverageRevenueProps } from "../types";

class CustomizedAxisTick extends PureComponent<any, any> {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={0}
          textAnchor="end"
          fill="#666"
          transform="rotate(-50)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

const formatMonthName = (label: string): string => {
  const monthMapping: Record<string, string> = {
    Jan: "January",
    Feb: "February",
    Mar: "March",
    Apr: "April",
    May: "May",
    Jun: "June",
    Jul: "July",
    Aug: "August",
    Sep: "September",
    Oct: "October",
    Nov: "November",
    Dec: "December",
  };
  const formattedLabel =
    label.charAt(0).toUpperCase() + label.slice(1).toLowerCase(); // Normalize case
  return monthMapping[formattedLabel] || label;
};

const formatNumber = (num: number): string => {
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1) + "M";
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1) + "k";
  }
  return num.toString();
};

// Helper function to replace "LOS" and "Lead-time" with "bookings"
const replaceLabel = (label: string) => {
  if (label === "LOS" || label === "Lead-time") {
    return "Bookings";
  }
  return label;
};

export default class AverageRevenue extends PureComponent<AverageRevenueProps> {
  getMaxValue = () => {
    const { data, dataKey } = this.props;
    return Math.max(...data?.map((item) => item[dataKey])) || 0;
  };

  render() {
    const { data, dataKey } = this.props;
    const maxValue = this.getMaxValue();
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 10,
            right: 15,
            left: 0,
            bottom: 0,
          }}
          barSize={15}
          style={{ marginLeft: "auto", marginRight: "auto", paddingLeft: 5 }}
        >
          <XAxis
            dataKey="name"
            scale="point"
            className="text-xs"
            interval={0}
            tick={
              dataKey === "LOS" || dataKey === "Lead-time" ? undefined : (
                <CustomizedAxisTick formatter={formatMonthName} />
              )
            }
          />

          <YAxis
            domain={[0, Math.ceil(maxValue * 1.2)]}
            tickCount={11}
            className="!pl-4"
            allowDataOverflow={true}
            tickFormatter={(tick) => formatNumber(tick)}
            style={{ fontSize: 14 }}
            tickMargin={5}
          />

          <Tooltip
            formatter={(value, name) => {
              if (dataKey === "PM Fees") {
                return [formatNumberWithCommas(value as number), "Earnings"];
              } else if (dataKey === "LOS" || dataKey ==="Lead-time") {
                return [formatNumberWithCommas(value as number), "Bookings"]
              } else {
                return [
                  formatNumberWithCommas(value as number),
                  name as string,
                ];
              }
            }}
            labelFormatter={(label) => {
              const formattedLabel = formatMonthName(label);
              const index = data.findIndex((item) => item.name === label);
              if (
                (dataKey === "LOS" || dataKey === "Lead-time") &&
                index >= 2
              ) {
                return `${formattedLabel} nights`;
              }
              return formattedLabel;
            }}
          />

          <Legend content={() => null} />
          {/* <CartesianGrid strokeDasharray="3 3" /> */}

          <Bar
            dataKey={dataKey}
            fill="#41645c"
            background={{ fill: "#eee" }}
            className="display-none"
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
