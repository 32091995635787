import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  CircularProgress,
} from "@mui/material";

interface connectType {
  handleSubmit: () => void;
  handleBack: () => void;
  clientID: string;
  setClientID: React.Dispatch<React.SetStateAction<string>>;
  clientSecret: string;
  setClientSecret: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
}
const Connect: React.FC<connectType> = ({
  handleSubmit,
  handleBack,
  clientID,
  setClientID,
  clientSecret,
  setClientSecret,
  isLoading,
}) => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <Card className="w-full max-w-lg p-5">
        <CardContent className="space-y-4">
          <Typography variant="h5" component="h2" className="text-center mb-4">
            API Credentials
          </Typography>

          <TextField
            label="Client ID"
            variant="outlined"
            fullWidth
            className="mb-4"
            value={clientID}
            onChange={(e) => setClientID(e.target.value)}
          />

          <TextField
            label="Client Secret"
            variant="outlined"
            fullWidth
            className="mb-4"
            type="password"
            value={clientSecret}
            onChange={(e) => setClientSecret(e.target.value)}
          />

          <div className="flex justify-between">
            <Button variant="text" color="primary" onClick={handleBack}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={clientID === "" || clientSecret === "" || isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : "Connect"}
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Connect;
