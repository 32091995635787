import type { ColumnsType } from "antd/es/table";
import { Property } from "./types";

export const HOME_BOOKING_SOURCE_TABLE = (
  revenueSelection: "net" | "gross"
): ColumnsType<Property> => [
  {
    title: "Source",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Revenue",
    dataIndex: revenueSelection === "net" ? "revenue" : "gross_revenue",
    key: "revenue",
  },
  {
    title: "Bookings",
    dataIndex: "bookings",
    key: "bookings",
  },
];


export const shortMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];