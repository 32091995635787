import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Card,
  CardContent,
  Button,
  MenuItem,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { PMSDROPDOWN } from "./constants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface SelectedPmsType {
  handleNext: () => void;
  pmsName: string;
  setPmsName: React.Dispatch<React.SetStateAction<string>>;
}

const SelectPMS: React.FC<SelectedPmsType> = ({
  handleNext,
  pmsName,
  setPmsName,
}) => {
  return (
    <Card className="mx-auto w-3/4 bg-white shadow-lg rounded-lg shadow-lg p-10">
      <CardContent className="space-y-2">
        <Typography variant="h5" component="h1" color="primary">
          Select Your PMS
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Select from the list of available Property Management System
          Integrations.
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Enjoy Pricing Ease of Mind!
        </Typography>

        <FormControl className="w-full bg-white">
          <Select
            displayEmpty
            value={pmsName}
            onChange={(e: SelectChangeEvent<string>) =>
              setPmsName(e.target.value)
            }
            input={<OutlinedInput />}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem disabled value="">
              <em>Select PMS</em>
            </MenuItem>
            {PMSDROPDOWN.map(({ name, active }) => (
              <MenuItem key={name} value={name} disabled={!active}>
                {name}
              </MenuItem>
            ))}
          </Select>

          {/* Button aligned to the right */}
          <div className="w-full flex justify-end mt-2">
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{ mt: 1 }}
              className="p-2"
              disabled={pmsName === ""}
            >
              Continue
            </Button>
          </div>
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default SelectPMS;
