export const PORTFOLIOCHOICES = [
  {
    label: "Revenue",
    value: "revenue",
  },
  {
    label: "ADR",
    value: "adr",
  },
];

export const VARIANCETYPES = [
  {
    label: "%",
    value: "%",
  },
  {
    label: "$",
    value: "$",
  },
];

export const REVENUETYPES = [
  {
    label: "Net",
    value: "net",
  },
  {
    label: "Gross",
    value: "gross",
  },
];

export const LISTINGTYPES = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
];
