import React from "react";
import { Pagination } from "antd";

interface PaginationProps {
  currentPage: number;
  totalItems: number;
  pageSize: number;
  onPageChange: (page: number, pageSize: number) => void;
  onPageSizeChange: React.Dispatch<React.SetStateAction<number>>;
}

const ReusablePagination: React.FC<PaginationProps> = ({
  currentPage,
  totalItems,
  pageSize,
  onPageChange,
  onPageSizeChange,
}) => {
  const showTotal = (total: number, range: [number, number]) => {
    return `Showing ${total !== 0 ? range[0] : 0}-${range[1]} of ${total} records`;
  };

  return (
    <div className="flex flex-inline items-center justify-between pt-1 py-4 px-4">
      {/* Left aligned text */}
      <div className="text-sm text-gray-500">
        {showTotal(totalItems, [
          (currentPage - 1) * pageSize + 1,
          Math.min(currentPage * pageSize, totalItems),
        ])}
      </div>

      {/* Centered pagination */}
      <div className="">
        <Pagination
          current={currentPage}
          total={totalItems}
          defaultPageSize={pageSize}
          showSizeChanger
          onChange={onPageChange}
          onShowSizeChange={(curr, size) => onPageSizeChange(size)}
          className="custom-pagination pt-0"
          pageSizeOptions={["10", "20", "50", "100"]}
        />
      </div>
    </div>
  );
};

export default ReusablePagination;
