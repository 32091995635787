import axios from "axios";
import { useState } from "react";
import { TextField, Button } from "@mui/material";
import { Link } from "react-router-dom";

export const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await axios.post("/api/forgot-password", { email });
      // Handle successful request
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md space-y-6"
      >
        <h1 className="text-2xl font-bold text-center mb-6">Forgot Password</h1>

        <TextField
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          className="mb-4"
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          className="bg-blue-500 hover:bg-blue-600"
        >
          Send Reset Link
        </Button>

        <div className="text-center mt-4">
          <p className="text-sm text-gray-600">
            Remembered your password?{" "}
            <Link to="/login" className="text-blue-500 hover:underline">
              Login
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};
