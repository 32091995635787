import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import { ForgotPassword } from "./pages/auth/ForgotPassword";
import Pms from "./pages/pms";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./pages/home";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardLayoutBasic from "./pages/dashboard";
import NotFound from "./pages/404NotFound";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Quicksand",
      "Commissioner",
      "Montserrat",
      "Roboto",
      "sans-serif",
    ].join(","),
  },
});

const App: React.FC = () => (
  <div className="App font-sans">
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer className="mt-10"/>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/pms" element={<PrivateRoute element={<Pms />} />} />
          <Route path="/dashboard/*" element={<PrivateRoute element={<DashboardLayoutBasic />} />} />
          <Route path="*" element={<NotFound />} />  {/* Catch-all route */}
        </Routes>
      </Router>
    </ThemeProvider>
  </div>
);

export default App;
