import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

const HomeSkeleton: React.FC = () => {
  return (
    <Box className="grid w-full grid-cols-5 gap-6 py-6">
      <Skeleton className="min-h-48 !w-screen rounded-md" variant="rectangular" />
    </Box>
  );
};

export default HomeSkeleton;
