import { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { formatNumberWithCommas } from "../../utils";
import { BookingsProps } from "../types";

const formatNumber = (num: number): string => {
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1) + "M";
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1) + "k";
  }
  return num.toString();
};

class CustomizedAxisTick extends PureComponent<any, any> {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={0}
          textAnchor="end"
          fill="#666"
          transform="rotate(-50)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

const formatMonthName = (label: string): string => {
  const monthMapping: Record<string, string> = {
    Jan: "January",
    Feb: "February",
    Mar: "March",
    Apr: "April",
    May: "May",
    Jun: "June",
    Jul: "July",
    Aug: "August",
    Sep: "September",
    Oct: "October",
    Nov: "November",
    Dec: "December",
  };
  const formattedLabel =
    label.charAt(0).toUpperCase() + label.slice(1).toLowerCase(); // Normalize case
  return monthMapping[formattedLabel] || label;
};

export default class Bookings extends PureComponent<BookingsProps> {
  getMaxValue = () => {
    const { data, dataKey } = this.props;
    const maxi =
      dataKey === "Occupancy"
        ? 100.0
        : Math.max(...data?.map((item) => item[dataKey])) || 0;
    return maxi;
  };

  render() {
    const { data, dataKey, isBookedNight = false } = this.props;
    const maxValue = this.getMaxValue();

    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            top: 10,
            right: 15,
            left: 0,
            bottom: 0,
          }}
          barSize={8}
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <CartesianGrid
            horizontal={true}
            vertical={false}
            strokeDasharray="3 3"
          />
          <XAxis
            dataKey="name"
            className="text-xs"
            interval={0}
            tick={<CustomizedAxisTick formatter={formatMonthName} />}
          />
          <YAxis
            domain={
              dataKey === "Occupancy"
                ? [0, 100]
                : [0, Math.ceil(maxValue * 1.2)]
            }
            tickCount={11}
            className=" !pl-4"
            allowDataOverflow={true}
            tickFormatter={(tick) =>
              dataKey === "Occupancy" ? `${tick}%` : formatNumber(tick)
            }
          />

          <Tooltip
            formatter={(value, name, props) => {
              const { payload } = props;
              const label = payload && payload[0]?.name;

              const formattedLabel = label ? formatMonthName(label) : "";

              if (dataKey === "Occupancy") {
                return [
                  `${formatNumberWithCommas(value as number)}%`,
                  formattedLabel || name,
                ];
              } else if (name === "blocked") {
                return [`${value} Nights`, "Blocked"];
              } else if (name === "Nights" && isBookedNight) {
                return [value, "Nights"];
              } else if (name === "Nights") {
                return(
                   [`${value} nights`, "Owner Stay"]
                )
              }
                else {
                return [
                  formatNumberWithCommas(value as number),
                  formattedLabel || name,
                ];
              }
            }}
            labelFormatter={(label) => formatMonthName(label)}
          />

          <Legend content={() => null} />
          <Line
            type="monotone"
            dataKey={dataKey}
            stroke="#41645c"
            activeDot={{ r: 4 }}
            dot={false}
          />
          {dataKey === "Occupancy" ? (
            <Line type="monotone" dataKey="Adj Occupancy" stroke="#82ca9d" dot={false} activeDot={{ r: 4 }} />
          ) : (
            ""
          )}
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
