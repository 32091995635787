import { useEffect, useState } from "react";
import { Button, Card, TextField, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { CircularProgress } from "@mui/material";

import { DatePicker } from "antd";
import dayjs from "dayjs";

import {
  fetchPropertyTarget,
  customPMFeesTable,
} from "../../../services/targetAPI";
import { CONVERT_CURRENCY_CODE } from "../utils";

import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);

const CardListings: React.FC<{
  monthValues: { [key: string]: number };
  handleValueChange: (month: string, value: number) => void;
  propertyID: string | number | null;
  setMonthValues: React.Dispatch<
    React.SetStateAction<{
      [key: string]: number;
    }>
  >;
  selectedRowName: string | null;
  card_label: string;
}> = ({
  monthValues,
  handleValueChange,
  propertyID,
  setMonthValues,
  card_label,
}) => {
  const currentYear = dayjs().year();
  const startOfYear = dayjs(`${currentYear}-01-01`);
  const endOfYear = dayjs(`${currentYear}-12-31`);
  const [responseValues, setReponseValues] = useState<{
    [key: string]: number;
  }>({});
  const [targetLoading, setTargetLoading] = useState(false);

  const [selectedCurrency, setSelectedCurrency] = useState<string | undefined>(
    undefined
  );

  const [selectedMonths, setSelectedMonths] = useState<string[]>([]);

  const handleMonthPeriodChange = async (dates: any) => {
    if (dates && propertyID) {
      const [start, end] = dates?.map((date: any) => dayjs(date)) || [
        null,
        null,
      ];
      const startMonth = dayjs(start).format("YYYY-MM");
      const endMonth = dayjs(end).format("YYYY-MM");
      try {
        setTargetLoading(true);
        let api;
        if (card_label === "PM Fees") {
          api = await customPMFeesTable({
            propertyID,
            params: {
              start_month: startMonth,
              end_month: endMonth,
              is_from_dashboard: true,
            },
          });
        } else {
          api = await fetchPropertyTarget({
            propertyID,
            params: {
              start_month: startMonth,
              end_month: endMonth,
            },
          });
        }
        const data = api.data;
        if (data && data.result) {
          const res = data.result;
          let newMonthValues;
          if (card_label === "PM Fees") {
            newMonthValues = res.reduce((acc: any, item: any) => {
              acc[`${item.month.toString().padStart(2, "0")}`] = item.pm_fees;
              return acc;
            }, {});
          } else {
            newMonthValues = res.reduce((acc: any, item: any) => {
              acc[`${item.month.toString().padStart(2, "0")}`] =
                item.target_amount;
              return acc;
            }, {});
          }

          if (data.currency) {
            setSelectedCurrency(data.currency);
          }
          if (start && end) {
            const startMonth = start.startOf("month");
            const endMonth = end.endOf("month");
            const newSelectedMonths: string[] = [];

            for (
              let m = startMonth;
              m.isSameOrBefore(endMonth);
              m = m.add(1, "month")
            ) {
              const monthKey = m.format("YYYY-MM");
              newSelectedMonths.push(monthKey);
            }

            setSelectedMonths(newSelectedMonths);
          } else {
            setSelectedMonths([]);
          }
          setMonthValues(newMonthValues);
          setReponseValues(newMonthValues);
        }
      } catch (error) {
        console.error("Error fetching property targets:", error);
      } finally {
        setTargetLoading(false);
      }
    }
  };

  const handleReset = () => {
    setMonthValues(responseValues);
  };

  useEffect(() => {
    handleMonthPeriodChange([startOfYear, endOfYear]);
  }, []);

  return (
    <div className="!m-0 pt-6">
      <div className="flex flex-cols-2 min-w-100 justify-between items-center py-3 px-2  bg-gray-100  rounded">
        <div className="w-full">
          <Typography>{card_label}</Typography>
        </div>
        <div className="w-full">
          <div>
            <div className="text-right">
              <div className="">
                <DatePicker.RangePicker
                  format="YYYY-MM"
                  defaultValue={[startOfYear, endOfYear]}
                  onChange={handleMonthPeriodChange}
                  picker="month"
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode as HTMLElement
                  }
                  allowClear={false}
                  className="w-3/4 p-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full flex justify-center items-center">
        <div className="rounded-md shadow-md w-full space-y-4">
          {targetLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div className="max-h-80 overflow-y-auto">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-2 bg-gray-100">
                {selectedMonths.map((month) => (
                  <div key={month}>
                    <Card className="p-4">
                      <TextField
                        label={`${new Date(month).toLocaleString("default", {
                          month: "long",
                        })} ${new Date(month).getFullYear()}`} // Month and Year
                        type="number"
                        onChange={(e) =>
                          handleValueChange(month, parseFloat(e.target.value))
                        }
                        value={monthValues[month] || ""}
                        variant="outlined"
                        fullWidth
                        slotProps={{
                          input: {
                            startAdornment: (
                              <InputAdornment position="start">
                                {card_label === "PM Fees"
                                  ? "%"
                                  : CONVERT_CURRENCY_CODE(
                                      selectedCurrency || ""
                                    )}
                              </InputAdornment>
                            ),
                            inputProps: { min: 0 },
                          },
                        }}
                      />
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="flex justify-between mt-5 pb-3 pl-2">
            <Button variant="outlined" onClick={handleReset} className="!bg-white">
              Reset
            </Button>
          </div>
        </div>
      </div>
      <style>
          {`
          .MuiPaper-root {
           box-shadow: none!important;
           background-color: rgb(243 244 246);
          }

          .MuiTextField-root {
            background-color: white;
          }
        `}
        </style>
    </div>
  );
};

export default CardListings;
