import React, { useEffect, useState, useCallback } from "react";
import { Table, Button } from "antd";
import { createStyles } from "antd-style";

import { configUpdateAPI, fetchConfigAPI } from "../../../services/targetAPI";

import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import PMFeesMonthlyTable from "./PMFeesMonthlyTable";
import TargetMonthlyTable from "./TargetMonthlyTable";

import { PMTableChanges, Property } from "./types";

import { LISTINGS_SETTINGSCOLUMN } from "./constants";

const useStyle = createStyles(({ css }) => ({
  customTable: css`
    .ant-table-row-expand-icon-cell {
      width: 30px !important;
    }
  `,
}));

const ConfigTable: React.FC<{
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setConfigureTableOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isEditing, setIsEditing, setConfigureTableOpen }) => {
  const { styles } = useStyle();
  const [configData, setConfigData] = useState<Property[] | []>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSavingLoading, setIsSavingLoading] = useState<boolean>(false);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

  const [offset, setOffset] = useState<number>(0);
  const [limit] = useState<number>(60);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const [mainTableChanges, setMainTableChanges] = useState<any>({});
  const [pmFeesChanges, setPmFeesChanges] = useState({});
  const [targetTableChanges, setTargetTableChanges] = useState({});

  const fetchConfig = useCallback(async () => {
    if (!hasMore) return; // Stop fetching if no more data

    try {
      setIsLoading(true);
      const response = await fetchConfigAPI(offset, limit); // Adjusted fetch call
      const newResults = response || [];
      let count = 0;
      setConfigData((prev) => {
        const filteredResults = newResults.filter(
          (newItem: Property) =>
            !prev.some(
              (existingItem) =>
                existingItem.internal_listing_name ===
                  newItem.internal_listing_name &&
                existingItem.id === newItem.id
            )
        );
        count = prev.length + filteredResults?.length;
        return [...prev, ...filteredResults];
      });

      setOffset(count);

      if (newResults.length < limit) {
        setHasMore(false);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [offset, limit, hasMore]);

  const handleMainTableEdit = (
    id: string | number,
    field: string,
    value: string | number
  ): void => {
    setMainTableChanges((prev: any) => ({
      ...prev,
      [id]: {
        ...(prev[id] || {}),
        [field]: value,
      },
    }));
  };

  const handlePmFeesEdit = (
    id: string | number,
    month: string,
    value: string | number | null
  ): void => {
    setPmFeesChanges((prev: PMTableChanges) => ({
      ...prev,
      [id]: {
        ...(prev[id] || {}),
        [month]: value,
      },
    }));
  };

  const handleTargetEdit = (
    id: string | number,
    month: string,
    value: string | number | null
  ): void => {
    setTargetTableChanges((prev: PMTableChanges) => ({
      ...prev,
      [id]: {
        ...(prev[id] || {}),
        [month]: value,
      },
    }));
  };

  const handleSave = async () => {
    setIsSavingLoading(true);
    const payload = {
      mainTable: mainTableChanges,
      pmFees: pmFeesChanges,
      targetTable: targetTableChanges,
    };

    try {
      await configUpdateAPI({ payload });
      setIsEditing(false);
      setConfigData((prevData) =>
        prevData.map((item: any) => {
          const updates = mainTableChanges[item?.id];
          return updates
            ? {
                ...item,
                ...updates,
                is_pm_fees_from_net:
                  updates["Net/Gross"]?.toLowerCase() === "net",
              }
            : item;
        })
      );
      setMainTableChanges({});
      setPmFeesChanges({});
      setTargetTableChanges({});
    } catch (err) {
      console.error("Error saving changes:", err);
    } finally {
      setIsSavingLoading(false);
    }
  };

  const handleEditToggle = (event: boolean) => {
    if (event) {
      isEditing && handleSave();
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    setPmFeesChanges({});
    setTargetTableChanges({});
    setMainTableChanges({});
  }, [isEditing]);

  const expandedRowRender = (record: Property) => {
    const isExpanded = expandedKeys.includes(record?.id);
    return (
      <>
        <div
          style={{ padding: "10px 20px" }}
          className="shadow-lg bg-white rounded-lg"
        >
          <PMFeesMonthlyTable
            propertyID={record?.id}
            standardPMFee={record?.pm_fees}
            isEditing={isEditing}
            handlePmFeesEdit={handlePmFeesEdit}
            isExpanded={isExpanded}
            pmFeesChanges={pmFeesChanges}
          />
        </div>
        <div
          style={{ padding: "10px 20px" }}
          className="shadow-lg bg-white rounded-lg mt-3"
        >
          <TargetMonthlyTable
            propertyID={record?.id}
            isEditing={isEditing}
            handleTargetEdit={handleTargetEdit}
            isExpanded={isExpanded}
            targetTableChanges={targetTableChanges}
          />
        </div>
      </>
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollHeight, scrollTop, clientHeight } =
        document.documentElement;

      if (scrollHeight - scrollTop - clientHeight <= 100) {
        fetchConfig();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [fetchConfig, offset]);

  useEffect(() => {
    if (offset === 0) {
      fetchConfig();
    }
  }, [fetchConfig]);

  return (
    <>
      <div className="flex w-full justify-between items-center bg-white px-5 py-3 mt-3 rounded-lg shadow-lg">
        {isEditing ? (
          <span className="bg-white border border-bg-gray-100 text-[#0066b2] font-bold text-md px-2 py-1 rounded shadow-lg">
            <EditIcon className="mr-2" />
            Edit Mode
          </span>
        ) : (
          <span className="bg-white border border-bg-gray-100 text-[#0066b2] font-bold text-md px-2 py-1 rounded shadow-lg">
            <VisibilityIcon className="mr-2" />
            View Mode
          </span>
        )}
        <div className="flex items-center space-x-4">
          {isEditing && (
            <Button
              onClick={(e) => {
                handleEditToggle(false);
              }}
              style={{
                background: "#4B5563",
                color: "#fff",
              }}
              className="font-bold"
            >
              Cancel
            </Button>
          )}

          <Button
            onClick={(e) => {
              handleEditToggle(true);
            }}
            style={{
              background: "#007fff",
              color: "#fff",
            }}
            className="font-bold"
            loading={isSavingLoading}
          >
            {isEditing ? "Save" : "Edit"}
          </Button>
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <div style={{ overflowX: "auto" }}>
          <Table
            columns={LISTINGS_SETTINGSCOLUMN(isEditing, handleMainTableEdit)}
            dataSource={configData}
            rowKey="id"
            bordered
            className={`font-semibold ${styles.customTable}`}
            pagination={false}
            loading={isLoading}
            expandable={{
              expandedRowRender,
              expandRowByClick: false,
              expandedRowKeys: expandedKeys,
              expandIconColumnIndex: 0,
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <UpOutlined
                    onClick={(e) => {
                      onExpand(record, e);
                    }}
                  />
                ) : (
                  <DownOutlined onClick={(e) => onExpand(record, e)} />
                ),
              onExpand: (expanded, record) => {
                setExpandedKeys((prev) =>
                  expanded
                    ? [...prev, record.id]
                    : prev.filter((id) => id !== record.id)
                );
              },
            }}
          />
        </div>
        <style>
          {`
          .ant-table-row-expand-icon-cell {
            max-width: 10px !important; /* Adjust this value to make it narrower */
          }

          .ant-table-cell {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        `}
        </style>
      </div>
    </>
  );
};

export default ConfigTable;
