import apiClient from "./apiClient";

const fetch = (
  page = 1,
  per_page = 10,
  activeSelection = "active",
  searchValue = ""
) =>
  apiClient.get(
    `/api/properties/targets?tab=${activeSelection}&search=${searchValue}&page=${page}&per_page=${per_page}`
  );

export const fetchConfigAPI = async (
  offset: number,
  limit: number,
  search: string = ""
) => {
  const params = new URLSearchParams({
    offset: offset.toString(),
    limit: limit.toString(),
    search,
  });
  const response = await apiClient.get(
    `/api/properties/config?${params.toString()}`
  );
  return response.data;
};

const fetchPropertyTarget = async ({
  propertyID,
  params,
}: {
  propertyID: string | number;
  params: any;
}) => {
  return await apiClient.get(`/api/revenue-targets/${propertyID}/`, { params });
};

const update = ({
  propertyID,
  payload,
}: {
  propertyID: string | number;
  payload: any;
}) => apiClient.post(`/api/revenue-targets/${propertyID}/update`, payload);

const configUpdateAPI = ({ payload }: { payload: any }) =>
  apiClient.post(`/api/properties/config/update/`, payload);

const customPMFeesTable = async ({
  propertyID,
  params,
}: {
  propertyID: string | number;
  params: any;
}) => {
  return await apiClient.get(`/api/pmfees/${propertyID}/`, { params });
};

export {
  fetch,
  fetchPropertyTarget,
  update,
  customPMFeesTable,
  configUpdateAPI,
};
