import * as React from "react";
import { useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import StepContent from "@mui/material/StepContent";
import SelectPMS from "./registration/SelectPMS";
import Connect from "./registration/Connect&Deploy";
import apiClient from "../../services/apiClient";
import { useNavigate } from "react-router-dom";
import { setTokens } from "../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const steps = [
  {
    label: "Select PMS",
    description: `Select your Property Management System.`,
  },
  {
    label: "Connect + Deploy",
    description: `PMS Connection Details.`,
  },
];

const Register: React.FC = () => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [clientID, setClientID] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [pmsName, setPmsName] = useState<string>("");
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.auth.user);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const response = await apiClient.post("/api/register/pms", {
        clientID,
        clientSecret,
        pmsName,
      });
      dispatch(
        setTokens({
          user: {
            name: user?.name || "",
            email: user?.email || "",
            isActive: true,
          }
        })
      );
      navigate("/dashboard");
    } catch (err: any) {
      console.error(
        err?.response?.data?.error || "An unexpected error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="inline-flex w-full overflow-hidden h-fit">
      <div className="w-1/5 p-2">
        <Typography className="!font-extrabold !text-2xl text-center text-blue-500 py-5">
          Registration
        </Typography>
        <Box sx={{ maxWidth: 400, minHeight: "full" }}>
          <Stepper activeStep={activeStep} orientation="vertical" className="ml-6">
            {steps.map((step, index) => (
              <Step key={step.label} className="font-semibold">
                <StepLabel>{step.label}</StepLabel>
                <StepContent>
                  <Typography>{step.description}</Typography>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
      </div>
      <div className="grid items-center justify-center min-h-screen bg-gray-100 w-4/5">
        {activeStep === 0 && (
          <div>
            <SelectPMS
              handleNext={handleNext}
              pmsName={pmsName}
              setPmsName={setPmsName}
            />
          </div>
        )}
        {activeStep === 1 && (
          <>
            <Connect
              handleSubmit={handleSubmit}
              handleBack={handleBack}
              clientID={clientID}
              setClientID={setClientID}
              clientSecret={clientSecret}
              setClientSecret={setClientSecret}
              isLoading={isLoading}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Register;
