import React, { useEffect, useCallback, useState } from "react";
import { Container, IconButton, Typography } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import HomeSkelton from "../skeltons/HomeSkeltons";
import CustomLightTooltip from "../../../components/CustomLightTooltip";
import AverageRevenue from "./homeCharts/AverageRevenue";
import Bookings from "./homeCharts/Bookings";
import BookingSourcePieChart from "./homeCharts/BookingSourceChart";
import BookingSourceTable from "./homeCharts/BookingSourceTable";
import FilterDropdown from "../../../components/FilterDropdown";
import ChartCard from "./ChartCard";
import { fetchDropdownValues } from "../../../services/bookingAPI";
import { DefaultOptionType } from "antd/es/select";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import {
  fetchAverageRevenueAPI,
  graphsParams,
  fetchChannelViewAPI,
  fetchAvailabilityAPI,
  fetchRevparViewAPI,
  fetchBlockedAPI,
  fetchOwnerStayAPI,
} from "../../../services/homeAPI";
import ToggleButton from "../../../components/ToggleButton";
import { REVENUETYPES } from "../portfolio/utils";
import { formatNumberWithCommas } from "../utils";
import PageHeader from "../../../components/PageHeader";

interface graphDataType {
  overall_revenue: string;
  overall_gross_revenue: string;
  overall_avg_adr: string;
  overall_avg_gross_adr: string;
  overall_avg_revpar: string;
  overall_avg_gross_revpar: string;
  total_bookings_year: string;
  total_nights_year: string;
  total_pm_fees: string;
  average_los_year: string;
  average_lead_year: string;
  rev_avg: any;
  rev_gross_avg: any;
  adr_avg: any;
  adr_gross_avg: any;
  bookings_avg: any;
  nights_avg: any;
  revpar_avg: any;
  revpar_gross_avg: any;
  length_of_stay: any;
  lead_time_data: any;
  pm_fee_avg: any;
}

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [selection, setSelection] = useState<string>("chart");
  const [selectionMonth, setSelectionMonth] = useState<string>("");
  const [selectedMonthData, setSelectedMonthData] = useState<any>([]);
  const [currentYear, setCurrentYear] = useState<number>(dayjs().year());
  const [listings, setListings] = useState<string[]>([]);
  const [listingValues, setListingValues] = useState<DefaultOptionType[]>([]);
  const [revenueSelection, setRevenueSelection] = useState<string>("net");

  const [averageRevenueData, setAverageRevenueData] = useState<graphDataType>({
    overall_revenue: "",
    overall_gross_revenue: "",
    overall_avg_adr: "",
    overall_avg_gross_adr: "",
    overall_avg_revpar: "",
    overall_avg_gross_revpar: "",
    total_bookings_year: "",
    total_nights_year: "",
    rev_avg: [],
    rev_gross_avg: [],
    adr_avg: [],
    adr_gross_avg: [],
    bookings_avg: [],
    nights_avg: [],
    revpar_avg: [],
    revpar_gross_avg: [],
    length_of_stay: [],
    average_los_year: "",
    average_lead_year: "",
    lead_time_data: [],
    pm_fee_avg: [],
    total_pm_fees: "",
  });

  const [graph2Data, setGraph2Data] = useState<any>({
    channel_percentage: [],
    detailed_view: [],
  });

  const [graph3Data, setGraph3Data] = useState<any>({
    occupancy_data: [],
    average_occupancy_year: null,
  });

  const [graph4Data, setGraph4Data] = useState<any>({
    revpar_avg: [],
    revpar_gross_avg: [],
    overall_avg_revpar: "",
    overall_avg_gross_revpar: "",
  });

  const [graph5Data, setGraph5Data] = useState<any>({
    blocked_data: [],
    overall_blocked_year: null,
  });

  const [graph6Data, setGraph6Data] = useState<any>({
    nights_avg: [],
    total_nights_year: null,
  });

  const fetchDropdownLoad = useCallback(async () => {
    try {
      const response = await fetchDropdownValues();
      const distinct_listings = response?.data?.distinct_listings || [];
      const listingsValues = distinct_listings.map((val: string) => ({
        label: val,
        value: val,
      }));

      setListingValues(listingsValues);
    } catch (error) {
      console.error("Failed to fetch dropdown values:", error);
      setListingValues([]);
    }
  }, []);

  const fetchAverageRevenue = useCallback(async () => {
    try {
      setLoading(true);
      const params: graphsParams = {
        year: currentYear,
        listing_name: listings.join(","),
      };

      const [graph1, graph2, graph3, graph4, graph5, graph6] =
        await Promise.all([
          fetchAverageRevenueAPI({ params }),
          fetchChannelViewAPI({ params }),
          fetchAvailabilityAPI({ params }),
          fetchRevparViewAPI({ params }),
          fetchBlockedAPI({ params }),
          fetchOwnerStayAPI({ params }),
        ]);
      setAverageRevenueData(graph1);
      setGraph2Data(graph2);
      setGraph3Data(graph3);
      setGraph4Data(graph4);
      setGraph5Data(graph5);
      setGraph6Data(graph6);
    } catch (error) {
      console.error("Failed to fetch dropdown values:", error);
      setListingValues([]);
    } finally {
      setLoading(false);
    }
  }, [currentYear, listings, setLoading]);

  const filterByMonth = () => {
    const selectedMonthShort = selectionMonth.split(" ")[0];

    setSelectedMonthData(
      averageRevenueData
        ? averageRevenueData?.lead_time_data?.find(
            (entry: any) => entry.month === selectedMonthShort
          )?.lead_time
        : []
    );
  };

  useEffect(() => {
    selectionMonth && filterByMonth();
  }, [selectionMonth, averageRevenueData]);

  useEffect(() => {
    fetchDropdownLoad();
  }, []);

  useEffect(() => {
    fetchAverageRevenue();
    setSelectionMonth("");
  }, [currentYear, listings]);

  return (
    <Container
      maxWidth="xl"
      className="p-4 flex flex-col space-y-4 bg-gray-100 h-full min-w-full !mx-0"
    >
      <div className="h-fit">
        <PageHeader text="Dashboard" />

        <div className="flex inline-flex w-full justify-between space-x-4 items-center bg-white px-5 py-2 mt-3 rounded-lg shadow-lg">
          <div className="w-1/4 h-full">
            <FilterDropdown
              options={listingValues}
              placeholder="Select Listings"
              selectedValues={listings}
              setSelectedValues={setListings}
            />
          </div>
          <div className="flex gap-4 h-full items-center">
            <ToggleButton
              selection={revenueSelection}
              setSelection={setRevenueSelection}
              Choices={REVENUETYPES}
            />
            <div className="w-36 pl-1">
              <DatePicker
                picker="year"
                value={dayjs(`${currentYear}`, "YYYY")}
                onChange={(date) => {
                  if (date) {
                    setCurrentYear(date.year());
                  }
                }}
                allowClear={false}
                className="w-1/8"
              />
            </div>
            <CustomLightTooltip title="Reset Filters">
              <IconButton
                onClick={() => {
                  setListings([]);
                  setCurrentYear(dayjs().year());
                }}
                className="!bg-white"
                aria-label="reset"
              >
                <RestartAltIcon />
              </IconButton>
            </CustomLightTooltip>
          </div>
        </div>
      </div>
      <div>
        {loading ? (
          <HomeSkelton />
        ) : (
          <div className="grid grid-cols-3 gap-4">
            <ChartCard
              title="Revenue"
              value={
                revenueSelection === "net"
                  ? (graph3Data?.currency
                      ? graph3Data?.currency + " "
                      : "AED ") +
                      formatNumberWithCommas(
                        averageRevenueData?.overall_revenue
                      ) || ""
                  : (graph3Data?.currency
                      ? graph3Data?.currency + " "
                      : "AED ") +
                      formatNumberWithCommas(
                        averageRevenueData?.overall_gross_revenue
                      ) || ""
              }
            >
              <AverageRevenue
                data={
                  revenueSelection === "net"
                    ? averageRevenueData?.rev_avg
                    : averageRevenueData?.rev_gross_avg
                }
                dataKey="Revenue"
              />
            </ChartCard>

            <ChartCard
              title="Average Daily Rate"
              value={
                revenueSelection === "net"
                  ? (graph3Data?.currency
                      ? graph3Data?.currency + " "
                      : "AED ") +
                      formatNumberWithCommas(
                        averageRevenueData?.overall_avg_adr
                      ) || ""
                  : (graph3Data?.currency
                      ? graph3Data?.currency + " "
                      : "AED ") +
                      formatNumberWithCommas(
                        averageRevenueData?.overall_avg_gross_adr
                      ) || ""
              }
            >
              <AverageRevenue
                data={
                  revenueSelection === "net"
                    ? averageRevenueData?.adr_avg
                    : averageRevenueData?.adr_gross_avg
                }
                dataKey="ADR"
              />
            </ChartCard>

            <ChartCard
              title="Occupancy"
              value={graph3Data?.average_occupancy_year}
            >
              <Bookings data={graph3Data?.occupancy_data} dataKey="Occupancy" />
            </ChartCard>

            <ChartCard
              title="Booking Source"
              value=""
              isFilter={true}
              selection={selection}
              setSelection={setSelection}
            >
              {selection === "chart" ? (
                <BookingSourcePieChart data={graph2Data?.channel_percentage} isNet={revenueSelection === "net"} />
              ) : (
                <BookingSourceTable
                  data={graph2Data?.detailed_view}
                  revenueSelection={revenueSelection}
                />
              )}
            </ChartCard>

            <ChartCard
              title="RevPAR"
              value={
                revenueSelection === "net"
                  ? (graph3Data?.currency
                      ? graph3Data?.currency + " "
                      : "AED ") +
                      formatNumberWithCommas(graph4Data?.overall_avg_revpar) ||
                    ""
                  : (graph3Data?.currency
                      ? graph3Data?.currency + " "
                      : "AED ") +
                      formatNumberWithCommas(
                        graph4Data?.overall_avg_gross_revpar
                      ) || ""
              }
            >
              <Bookings
                data={
                  revenueSelection === "net"
                    ? graph4Data?.revpar_avg
                    : graph4Data?.revpar_gross_avg
                }
                dataKey="RevPAR"
              />
            </ChartCard>

            <ChartCard
              title="Bookings"
              value={
                formatNumberWithCommas(
                  averageRevenueData?.total_bookings_year
                ) || ""
              }
            >
              <Bookings
                data={averageRevenueData?.bookings_avg}
                dataKey="Bookings"
              />
            </ChartCard>

            <ChartCard
              title="Booked Nights"
              value={
                formatNumberWithCommas(averageRevenueData?.total_nights_year) ||
                ""
              }
            >
              <Bookings
                data={averageRevenueData?.nights_avg}
                dataKey="Nights"
                isBookedNight={true}
              />
            </ChartCard>

            <ChartCard
              title="Length of Stay"
              value={
                formatNumberWithCommas(averageRevenueData?.average_los_year) ||
                ""
              }
            >
              <AverageRevenue
                data={averageRevenueData?.length_of_stay}
                dataKey="LOS"
              />
            </ChartCard>

            <ChartCard
              title="Booking Lead Time"
              value={
                formatNumberWithCommas(averageRevenueData?.average_lead_year) ||
                ""
              }
            >
              <AverageRevenue
                data={averageRevenueData.lead_time_data || []}
                dataKey="Lead-time"
              />
            </ChartCard>

            <ChartCard
              title="Earnings"
              value={
                (graph3Data?.currency ? graph3Data?.currency + " " : "AED ") +
                  formatNumberWithCommas(averageRevenueData?.total_pm_fees) ||
                "0"
              }
            >
              <AverageRevenue
                data={averageRevenueData?.pm_fee_avg}
                dataKey="PM Fees"
              />
            </ChartCard>

            <ChartCard
              title="Owner Stays"
              value={
                formatNumberWithCommas(graph6Data?.total_nights_year) || "0"
              }
            >
              <Bookings data={graph6Data?.nights_avg} dataKey="Nights" />
            </ChartCard>

            <ChartCard
              title="Blocked Dates"
              value={
                formatNumberWithCommas(graph5Data?.overall_blocked_year) || ""
              }
            >
              <Bookings data={graph5Data?.blocked_data} dataKey="blocked" />
            </ChartCard>
          </div>
        )}
      </div>
    </Container>
  );
};

export default Home;
