import React from "react";
import { Select, Tag } from "antd";
import type { SelectProps } from "antd";

type TagRender = SelectProps["tagRender"];

interface FilterDropdownProps {
  options: SelectProps["options"];
  placeholder?: string;
  style?: React.CSSProperties;
  selectedValues: string[];
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>;
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({
  options,
  placeholder = "Filter listings",
  style,
  selectedValues,
  setSelectedValues,
}) => {

  const onChange = (value: string[]) => {
    setSelectedValues(value);
  };

  const tagRender: TagRender = (props) => {
    const { label, value, closable, onClose } = props;

    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color={"blue"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4 }}
      >
        {label?.toString().toUpperCase()}
      </Tag>
    );
  };

  return (
    <Select
      allowClear
      mode="multiple"
      tagRender={tagRender}
      className="w-full h-full"
      placeholder={placeholder}
      style={{ width: "100%", height: "100%", ...style }}
      options={options}
      value={selectedValues}
      onChange={onChange}
      dropdownStyle={{ maxHeight: "300px", overflowY: "auto" }}
      maxTagCount="responsive"
      maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} more`}
    />
  );
};

export default FilterDropdown;
