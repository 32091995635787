import { Table } from "antd";
import { createStyles } from "antd-style";
import { Property } from "./types";
import { PERFORMANCE_COLUMNS } from "./constants";

const useStyle = createStyles(({ css }) => ({
  customTable: css`
    antd-table {
      antd-table-container {
        antd-table-body,
        antd-table-content {
          scrollbar-width: thin;
          scrollbar-color: unset;
        }
      }
    }
  `,
}));

const PerformanceTable: React.FC<{
  data: Property[];
  isLoading: boolean;
}> = ({ data, isLoading }) => {
  const { styles } = useStyle();
  return (
    <>
      <Table
        columns={PERFORMANCE_COLUMNS}
        dataSource={data}
        rowKey="id"
        bordered
        className={`font-semibold ${styles.customTable}`}
        pagination={false}
        loading={isLoading}
        scroll={{ x: "calc(850px + 40%)" }}
        // sticky
        // sticky
      />
    </>
  );
};

export default PerformanceTable;
