import { useCallback, useEffect, useState } from "react";

import { Container, Typography } from "@mui/material";
import ScoreCard from "./ScoreCard";
import PerformanceTable from "./PerformanceTable";
import dayjs, { Dayjs } from "dayjs";

import useSearchPagination from "../../../hooks/useSearchPaginationFetch";
import { ITEMS_PER_PAGE } from "../utils";
import RangePicker from "../../../components/RangePicker";
import {
  fetchPerformanceList,
  fetchPerformanceScoreCard,
  PerformanceParams,
} from "../../../services/performance";
import ReusablePagination from "../../../components/PaginationComponent";
import ScoreCardSkelton from "../skeltons/ScoreCardSkelton";
import NumberConverter from "../../../utils/NumberFormatter";
import PageHeader from "../../../components/PageHeader";
import { Input } from "antd";
import useDebounce from "../../../hooks/useDebounce";

const Performance = () => {
  const today = dayjs();
  const { Search } = Input;
  const {
    isLoading,
    setIsLoading,
    currentPageNumber,
    currentPageSize,
    setCurrentPageNumber,
    setCurrentPageSize,
    searchValue,
    setSearchValue,
  } = useSearchPagination("", ITEMS_PER_PAGE);
  const [dateRange, setDateRange] = useState<{
    startDate: Dayjs;
    endDate: Dayjs;
  } | null>({ startDate: today.startOf("year"), endDate: today.endOf("year") });
  const [performanceData, setPerformanceData] = useState<{
    results: any[];
    count: number;
  }>({ results: [], count: 0 });
  const [performanceScoreCardData, setPerformanceScoreCardData] = useState<{
    results: any[];
    count: number;
  }>({ results: [], count: 0 });

  const debouncedSearchValue = useDebounce(searchValue, 300);

  const [scoreCardLoader, setScoreCardLoader] = useState(false);

  const firstRowData = performanceScoreCardData.results.slice(0, 5);
  const secondRowData = performanceScoreCardData.results.slice(5);

  const fetchTableData = useCallback(
    async (val?: any) => {
      try {
        setIsLoading(true);
        setScoreCardLoader(true);

        const params: PerformanceParams = {
          page: currentPageNumber,
          per_page: currentPageSize,
          start_date: val?.startDate
            ? val?.startDate?.format("YYYY-MM-DD")
            : dateRange?.startDate?.format("YYYY-MM-DD"),
          end_date: val?.endDate
            ? val?.endDate?.format("YYYY-MM-DD")
            : dateRange?.endDate?.format("YYYY-MM-DD"),
          search: searchValue,
        };

        const response = await fetchPerformanceList({
          params,
        });
        setPerformanceData(response);
      } catch (error) {
        console.error("Failed to fetch reservations:", error);
      } finally {
        setIsLoading(false);
        setScoreCardLoader(false);
      }
    },
    [currentPageNumber, currentPageSize, dateRange, searchValue]
  );

  const fetchScoreCardData = useCallback(
    async (val?: any) => {
      try {
        setIsLoading(true);

        const params: PerformanceParams = {
          start_date: val?.startDate
            ? val?.startDate?.format("YYYY-MM-DD")
            : dateRange?.startDate?.format("YYYY-MM-DD"),
          end_date: val?.endDate
            ? val?.endDate?.format("YYYY-MM-DD")
            : dateRange?.endDate?.format("YYYY-MM-DD"),
        };

        const response = await fetchPerformanceScoreCard({
          params,
        });

        const formattedData = [
          {
            key: "revenue",
            value: (
              <>
                <span>{response?.currency ? response?.currency : "AED"} </span>
                <NumberConverter value={response.revenue} />
              </>
            ),
            label: "Revenue",
          },
          {
            key: "adr",
            value: (
              <>
                <span>{response?.currency ? response?.currency : "AED"} </span>
                <NumberConverter value={response.adr} />
              </>
            ),
            label: "ADR",
          },
          {
            key: "occupancy",
            value: `${response.occupancy}`,
            label: "Occupancy",
          },
          {
            key: "adjOccupancy",
            value: `${response.adj_occupancy}`,
            label: "Adj Occupancy",
          },
          {
            key: "revpar",
            value: (
              <>
                <span>{response?.currency ? response?.currency : "AED"} </span>
                <NumberConverter value={response.revpar} />
              </>
            ),
            label: "RevPAR",
          },
          {
            key: "leadTime",
            value: <NumberConverter value={response.lead_time} />,
            label: "Lead Time",
          },
          {
            key: "los",
            value: <NumberConverter value={response.avg_los} />,
            label: "Length of Stay",
          },
          {
            key: "blockedNights",
            value: <NumberConverter value={response.blocked_nights} />,
            label: "Blocked Nights",
          },
          {
            key: "ownerStays",
            value: <NumberConverter value={response.owner_stay_count} />,
            label: "Owner Stays",
          },
          {
            key: "activeListings",
            value: <NumberConverter value={response.active_listing} />,
            label: "Active Listings",
          },
        ];

        setPerformanceScoreCardData({
          results: formattedData,
          count: response.count,
        });
      } catch (error) {
        console.error("Failed to fetch reservations:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [dateRange]
  );

  useEffect(() => {
    fetchTableData();
    fetchScoreCardData();
  }, [dateRange]);

  useEffect(() => {
    fetchTableData();
  }, [currentPageNumber, currentPageSize]);

  useEffect(() => {
    fetchTableData();
  }, [debouncedSearchValue]);

  return (
    <Container
      className="p-4 flex flex-col space-y-4 bg-gray-100 min-h-screen min-w-full !mx-0"
      maxWidth="xl"
    >
      <div className="flex w-full justify-start gap-4 items-center">
        <PageHeader text="Performance" />
      </div>
      <div className="flex inline-flex w-full justify-between space-x-4 items-center bg-white px-5 py-3 mt-3 rounded-lg shadow-lg">
        <RangePicker dateRange={dateRange} setDateRange={setDateRange} />
      </div>
      {/* ScoreCard starting*/}
      <div>
        {scoreCardLoader ? (
          <ScoreCardSkelton />
        ) : (
          <div>
            <div className="flex inline-flex w-full justify-between space-x-4 items-center mt-3">
              <ScoreCard data={firstRowData} />
            </div>
            <div className="flex inline-flex w-full justify-between space-x-4 items-center pb-3">
              <ScoreCard data={secondRowData} />
            </div>
          </div>
        )}
      </div>
      <div className="flex inline-flex w-full justify-end space-x-4 items-center pl-5 pt-3 mt-3 rounded-lg">
        <Search
          placeholder="Search Listings"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          className="w-1/4"
        />
      </div>
      <div className="w-full justify-between space-x-4 items-center !pt-0 !rounded-lg shadow-lg">
        <PerformanceTable
          isLoading={isLoading}
          data={performanceData?.results}
        />
        <ReusablePagination
          currentPage={currentPageNumber}
          onPageSizeChange={setCurrentPageSize}
          pageSize={currentPageSize}
          totalItems={performanceData.count}
          onPageChange={setCurrentPageNumber}
        />
      </div>
    </Container>
  );
};

export default Performance;
