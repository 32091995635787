import React, { useState } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Tooltip,
  TooltipProps ,
} from "recharts";
import { formatNumberWithCommas } from "../../utils";

import { PieChartComponentProps } from "../types";

const defaultColors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 1) * cos; // Adjust for smaller size
  const sy = cy + (outerRadius + 1) * sin; // Adjust for smaller size
  const mx = cx + (outerRadius + 2) * cos; // Adjust for smaller size
  const my = cy + (outerRadius + 2) * sin; // Adjust for smaller size
  const ex = mx + (cos >= 0 ? 1 : -1) * 10; // Adjust for smaller size
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g stroke="none" className="!outline-white">
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} fontSize={12}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        stroke="none"
        tabIndex={-1}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 2}
        outerRadius={outerRadius + 4}
        fill={fill}
        stroke="none"
        tabIndex={-1}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={1.5} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 8}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
        fontSize={10}
      >{`${value} bookings`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 8}
        y={ey}
        dx={0}
        dy={12}
        textAnchor={textAnchor}
        fill="#999"
        fontSize={10}
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const PieChartComponent: React.FC<PieChartComponentProps> = ({
  data,
  colors = defaultColors,
  innerRadius = 60,
  outerRadius = 80,
  dataKey = "value",
  cx = "50%",
  cy = "50%",
  width = "100%",
  height = "100%",
  activeIndex: initialActiveIndex = 0,
  onPieEnter,
  isNet,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(initialActiveIndex);

  const handlePieEnter = (_: any, index: number) => {
    setActiveIndex(index);
    if (onPieEnter) {
      onPieEnter(index);
    }
  };

  const CustomTooltip = ({ active, payload }: any) => {
    console.log(payload[0]?.payload?.revenue)
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: "#fff", padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }}>
          <p style={{ margin: 0 }}>{`${payload[0].name}`}</p>
          <p style={{ margin: 0 }}>{`Revenue: ${isNet ? payload[0]?.payload?.revenue : payload[0]?.payload?.gross_revenue}`}</p>
          <p style={{ margin: 0 }}>{`Bookings: ${formatNumberWithCommas(payload[0].value)}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <ResponsiveContainer width={width} height={height}>
        <PieChart>
          <Tooltip content={<CustomTooltip />} />
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={data}
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            fill="#8884d8"
            dataKey={dataKey}
            onMouseEnter={handlePieEnter}
            stroke="none"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <style>
        {` 
          svg:focus,
            svg:focus-visible,
            svg:active {
              outline: none;
              border: none;
            }

            .recharts-sector:focus, .recharts-sector:active {
              outline: none;
            }
        `}
      </style>
    </>
  );
};

export default PieChartComponent;
