import * as React from 'react';
import { MenuItem, MenuList, Divider, ListItemIcon } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';

export default function CustomMenu() {
  const handleMenuNavigation = (route: string) => () => {
    console.log(
      'Toolpad Core Account Demo --- CustomMenuItems --- handleMenuNavigation --- route: ',
      route,
    );
  };

  const mouseOnSubMenu = React.useRef<boolean>(false);

  const [subMenuAnchorEl, setSubMenuAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const subMenuOpen = Boolean(subMenuAnchorEl);

  const handleTriggerEnter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSubMenuAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleTriggerLeave = React.useCallback(() => {
    // Wait for 300ms to see if the mouse has moved to the sub menu
    setTimeout(() => {
      if (mouseOnSubMenu.current) {
        return;
      }
      setSubMenuAnchorEl(null);
    }, 300);
  }, []);

  return (
    <MenuList dense disablePadding>
      <MenuItem
        onMouseEnter={handleTriggerEnter}
        onMouseLeave={handleTriggerLeave}
        component="button"
        sx={{
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        Settings
      </MenuItem>

      <Divider />
    </MenuList>
  );
}
