import React from "react";

interface PageHeadingProps {
  text: string;
  className?: string; // Optional for additional styles
}

const PageHeader: React.FC<PageHeadingProps> = ({ text, className = "" }) => {
  return (
    <h1 className={`!text-gray-500 font-semibold${className}`} style={{ fontSize: "32px" }}>
      {text}
    </h1>
  );
};

export default PageHeader;
