import { Property } from "./types";
import type { ColumnsType } from "antd/es/table";
import NumberConverter from "../../../utils/NumberFormatter";



export const PERFORMANCE_COLUMNS: ColumnsType<Property> = [
    {
      title: "Listing",
      dataIndex: "uid",
      key: "uid",
      width: "20%",
      fixed: "left",
    },

    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "revenue",
      width: "10%",
      align: "center",
      render: (text, record) => (
        <div>
          {record.revenue && record.revenue !== "N/A" && record.revenue !== "0.00" ? (
            <>
              <span>AED </span>
              <NumberConverter value={record.revenue} />
            </>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      title: "Target",
      dataIndex: "target",
      key: "target",
      width: "10%",
      align: "center",
      render: (text, record) => (
        <div>
          {record.target && record.target !== "N/A" && record.target !== "0.00" ? (
            <>
              <span>AED </span>
              <NumberConverter value={record.target} />
            </>
          ) : (
            " "
          )}
        </div>
      ),
    },
    {
      title: "Variance",
      dataIndex: "comparison_value",
      key: "comparison_value",
      width: "10%",
      align: "center",
      render: (text, record) => (
        <>
          {record.comparison_value && record.comparison_value !=="N/A" ? (
            <>
              <span>AED </span>
              <NumberConverter value={record.comparison_value} />
            </>
          ) : (
            ""
          )}
          {/* <div>
            {record.comparison_percent &&
            record.comparison_percent !== "N/A" ? (
              <span>
                <NumberConverter value={record.comparison_percent} />%
              </span>
            ) : (
              ""
            )}
          </div> */}
        </>
      ),
    },
    {
      title: "Occupancy",
      dataIndex: "occupancy",
      key: "occupancy",
      width: "10%",
      align: "center",
    },
    {
      title: "Adj Occupancy",
      dataIndex: "adj_occupancy",
      key: "adj_occupancy",
      width: "10%",
      align: "center",
    },
    {
      title: "ADR",
      dataIndex: "adr",
      key: "adr",
      width: "10%",
      align: "center",
      render: (text, record) => (
        <div>
          {record.adr && record.adr !== "N/A" && record.adr !== "0.00" ?
          <><span>AED </span><NumberConverter value={record.adr} /></>
          : " "
    }
        </div>
      ),
    },
    {
      title: "RevPAR",
      dataIndex: "revpar",
      key: "revpar",
      width: "10%",
      align: "center",
      render: (text, record) => (
        <div>
          {record.adr && record.revpar !== "N/A" && record.revpar !== "0.00" ?
          <><span>AED </span><NumberConverter value={record.revpar} /></>
          : " "
    }
        </div>
      ),
    },
    {
      title: "Length of Stay",
      dataIndex: "avg_los",
      key: "avg_los",
      width: "10%",
      align: "center",
      render: (text, record) => (
        <div>
          {record.avg_los && record.avg_los !== "N/A" ?
          <><NumberConverter value={record.avg_los} /></>
          : " "
    }
        </div>
      ),
    },
    {
      title: "Blocked Nights",
      dataIndex: "blocked_nights",
      key: "blocked_nights",
      width: "10%",
      align: "center",
    },
    {
      title: "Owner Stays",
      dataIndex: "owner_stay_count",
      key: "owner_stay_count",
      width: "10%",
      align: "center",
    },
    {
      title: "Unbooked nights",
      dataIndex: "unbooked_nights",
      key: "unbooked_nights",
      width: "10%",
      align: "center",
    },
  ];