import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Account,
  AuthenticationContext,
  SessionContext,
  Session,
} from "@toolpad/core";
import store, { RootState } from "../../redux/store";
import DashboardRoutes from "./DashboardRoutes";
import { Button } from "@mui/material";
import { clearTokens } from "../../redux/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import CustomMenu from "./CustomMenu";
import Modal from "@mui/material/Modal";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import { Navbar } from "../dashboard/mainNavBar";

export default function DashboardLayoutBasic() {
  const [session, setSession] = useState<Session | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const accessToken = useSelector((state: RootState) => state.auth.accessToken);
  const isActive = useSelector(
    (state: RootState) => state.auth.user?.isActive || false
  );
  const user = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (!accessToken || !isActive) {
      navigate("/register");
    }
  }, [accessToken, isActive, navigate]);

  useEffect(() => {
    if (user) {
      setSession({
        user: {
          name: user.name,
          email: user.email,
        },
      });
    } else {
      setSession(null);
    }
  }, [user]);

  const authentication = React.useMemo(
    () => ({
      signIn: () => setSession({}),
      signOut: () => setModalOpen(true),
    }),
    []
  );

  const handleSave = () => {
    setSession(null);
    store.dispatch(clearTokens());
    window.location.href = "/login";
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div
        className="w-full h-full"
      >
        <AuthenticationContext.Provider value={authentication}>
          <SessionContext.Provider value={session}>
            <div className="flex items-center justify-between w-full pr-12">
            <Navbar />
            </div>
            <div className="mt-16"><DashboardRoutes /></div>
          </SessionContext.Provider>
        </AuthenticationContext.Provider>
      </div>
      <Modal open={modalOpen} onClose={handleClose}>
        <div className="w-full h-full flex justify-center items-center">
          <div className="bg-white p-8 rounded-md shadow-lg w-[25%] max-w-4xl space-y-4">
            <Typography className="!text-gray-500 text-lg">
              Are you sure you want to logout?
            </Typography>
            <div className="flex justify-between mt-7 px-10 bg-white text-black">
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={handleSave}
              >
                Yes
              </LoadingButton>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
