import ListingsTable from "./ListingsDashboard";
import { Container, Typography, Tabs, Tab } from "@mui/material";
import { Input } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";
import useSearchPagination from "../../../hooks/useSearchPaginationFetch";
import { LISTINGTYPES } from "../portfolio/utils";
import ToggleButton from "../../../components/ToggleButton";

import { ITEMS_PER_PAGE } from "../utils";
import ConfigTable from "./ListingConfigureTable";
import PageHeader from "../../../components/PageHeader";

const ListingsDashboard = () => {
  const { Search } = Input;
  const [activeSelection, setActiveSelection] = useState<string>("active");

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [configureTableOpen, setConfigureTableOpen] = useState<boolean>(() => {
    return searchParams.get("configureTableOpen") === "true";
  });

  const [isEditing, setIsEditing] = useState(false);


  const {
    searchValue,
    setSearchValue,
  } = useSearchPagination("", ITEMS_PER_PAGE);

  const debouncedSearchValue = useDebounce(searchValue, 500);

  useEffect(() => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.set("configureTableOpen", String(configureTableOpen));
    navigate(`?${updatedParams.toString()}`, { replace: true });
  }, [configureTableOpen, navigate, searchParams]);

  return (
    <Container
      className="p-4 flex flex-col space-y-4 bg-gray-100 min-h-screen min-w-full !mx-0"
      maxWidth="xl"
    >
      <div className="flex inline-flex w-full justify-between gap-4 items-center">
        <PageHeader text="Listings" />
        <Tabs
          onChange={(e, val) => setConfigureTableOpen(val === "config")}
          value={configureTableOpen ? "config" : "dashboard"}
          aria-label="Tabs where each tab needs to be selected manually"
        >
          <Tab label="Dashboard" value="dashboard" className="!font-semibold !capitalize" />
          <Tab label="Settings" value="config" className="!font-semibold !capitalize" />
        </Tabs>
      </div>
      {configureTableOpen === false ? (
        <>
          <div className="flex w-full justify-between items-center bg-white px-5 py-3 mt-3 rounded-lg shadow-lg">
            <Search
              placeholder="Search Units"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              className="w-1/4"
            />
            <div className="flex space-x-4 items-center">
              <ToggleButton
                Choices={LISTINGTYPES}
                selection={activeSelection}
                setSelection={setActiveSelection}
              />
            </div>
          </div>
          <div className="relative">
            <div className="overflow-x-auto rounded-lg shadow-lg">
              <ListingsTable  activeSelection={activeSelection} searchValue={searchValue} />
            </div>
          </div>
        </>
      ) : (
        <ConfigTable
          setIsEditing={setIsEditing}
          isEditing={isEditing}
          setConfigureTableOpen={setConfigureTableOpen}
        />
      )}
    </Container>
  );
};

export default ListingsDashboard;
