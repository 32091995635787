import apiClient from "./apiClient";

export interface ReservationParams {
  channel?: string;
  listing?: string;
  arrival_date_after?: string | null;
  arrival_date_before?: string | null;
  page?: number;
  per_page?: number;
}

export const fetchReservationList = async ({
  params,
}: {
  params: ReservationParams;
}) => {
  try {
    const response = await apiClient.get(`/api/reservations/`, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching reservation list", error);
    throw error;
  }
};

export const fetchExportData = async ({
    params,
  }: {
    params: ReservationParams;
  }) => {
    try {
      const response = await apiClient.get(`/api/export-reservations/`, {
        params,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching reservation list", error);
      throw error;
    }
  };
export const fetchDropdownValues = async () =>
  await apiClient.get(`/api/distinct-channels-listings/`);