import { useState } from "react";
import { Form, Input, Select, Radio, Checkbox, Button } from "antd";
import { Container, TextField, Typography } from "@mui/material";

const { Option } = Select;

const ScheduleDemo = () => {
  const [demoType, setDemoType] = useState<string>("");

  const handleSubmit = (values: any) => {
    console.log("Form Submitted: ", values);
    const mailtoLink = `mailto:binu@vafion.com?subject=Request for Personalised Demo&body=${encodeURIComponent(
      `Name: ${values.name}
      Email ID: ${values.mailID}
      Company: ${values.company}
      Phone: ${values.phone}
      Properties Managed: ${values.companysize}
      Comment: ${values.comment}`
    )}`;
    console.log(values.name)

    // Open the mail client
    window.location.href = mailtoLink;
  };

  const handleDemoTypeChange = (e: any) => {
    setDemoType(e.target.value);
  };

  return (
    <Container>
      <div className="grid grid-cols-2 gap-5 w-full mx-auto h-screen items-center">
        <div className="">
          <img src="contact us.avif" width={800} height={800} alt="image" />
        </div>
        <div className="text-center px-5 pt-10">
          <Typography variant="h4" className="!font-semibold">
            Request a Personalised Demo
          </Typography>
          <Form layout="vertical" onFinish={handleSubmit} className="pt-5">
            <div className="flex flex-col-2 min-w-full space-x-2">
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your name!",
                  },
                ]}
                className="w-1/2"
              >
                <Input placeholder="Enter your name" />
              </Form.Item>

              <Form.Item
                label="Official Email ID"
                name="mailID"
                rules={[{ required: true, message: "Please enter Email ID!" }]}
                className="w-1/2"
              >
                <Input placeholder="Enter Email ID" />
              </Form.Item>
            </div>
            <div className="flex flex-col-2 min-w-full space-x-2">
              <Form.Item
                label="Company Name"
                name="company"
                rules={[
                  {
                    required: true,
                    message: "Enter your company name!",
                  },
                ]}
                className="w-1/2"
              >
                <Input placeholder="Enter your company name" />
              </Form.Item>

              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[{ required: true, message: "Enter phone number!" }]}
                className="w-1/2"
              >
                <Input placeholder="Enter phone number" />
              </Form.Item>
            </div>
            <div className="flex flex-col-2 min-w-full space-x-2">
              <Form.Item
                label="How many properties are you managing?"
                name="companysize"
                rules={[
                  {
                    required: true,
                    message: "Please select any one!",
                  },
                ]}
              >
                <Radio.Group onChange={handleDemoTypeChange} value={demoType}>
                  <div className="flex gap-4">
                    <Radio.Button value="50" className="card-radio">
                      1-50
                    </Radio.Button>

                    <Radio.Button value="100" className="card-radio">
                      50-100
                    </Radio.Button>

                    <Radio.Button value="300" className="card-radio">
                      100-300
                    </Radio.Button>
                    <Radio.Button value="above" className="card-radio">
                      300 & above
                    </Radio.Button>
                  </div>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className="flex flex-col-2 min-w-full space-x-2">
              <Form.Item label="Comment" name="comment" className="w-full">
                <div className="border border-gray-300 rounded">
                  <TextField
                    placeholder="Enter your comment"
                    className="w-full !min-h-20"
                  />
                </div>
              </Form.Item>
            </div>
            {/* <Form.Item
              name="notARobot"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: "Please confirm you're not a robot!",
                },
              ]}
            >
              <Checkbox>I am not a robot</Checkbox>
            </Form.Item> */}

            <Form.Item className="text-center pt-0">
              <Button
                variant="outlined"
                color="primary"
                className="hover:bg-blue-600 w-1/3"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <style>
        {`
          fieldset{
          display: none;
          }
        `}
      </style>
    </Container>
  );
};

export default ScheduleDemo;
