import apiClient from "./apiClient";

export interface graphsParams {
  year?: number;
  listing_name?: string;
}

export const fetchAverageRevenueAPI = async ({
  params,
}: {
  params: graphsParams;
}) => {
  try {
    const response = await apiClient.get(`/api/dashboard/average-revenue`, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching home data", error);
    throw error;
  }
};

export const fetchChannelViewAPI = async ({
  params,
}: {
  params: graphsParams;
}) => {
  try {
    const response = await apiClient.get(`/api/dashboard/channel-view`, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching home data", error);
    throw error;
  }
};

export const fetchRevparViewAPI = async ({
    params,
  }: {
    params: graphsParams;
  }) => {
    try {
      const response = await apiClient.get(`/api/dashboard/revpar-view`, {
        params,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching home data", error);
      throw error;
    }
  };

export const fetchAvailabilityAPI = async ({
  params,
}: {
  params: graphsParams;
}) => {
  try {
    const response = await apiClient.get(`/api/dashboard/home-availability`, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching home data", error);
    throw error;
  }
};

export const fetchBlockedAPI = async ({
    params,
  }: {
    params: graphsParams;
  }) => {
    try {
      const response = await apiClient.get(`/api/dashboard/home-blocked`, {
        params,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching home data", error);
      throw error;
    }
  };


  export const fetchOwnerStayAPI = async ({
    params,
  }: {
    params: graphsParams;
  }) => {
    try {
      const response = await apiClient.get(`/api/dashboard/home-ownerstay`, {
        params,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching home data", error);
      throw error;
    }
  };