import dayjs from "dayjs";
import CardListings from "./CardListings";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);

const TargetSettings: React.FC<{
  monthValues: { [key: string]: number };
  pmFeeMonthValues: { [key: string]: number };
  handleValueChange: (month: string, value: number) => void;
  handlePMFeesValueChange: (month: string, value: number) => void;
  propertyID: string | number | null;
  setMonthValues: React.Dispatch<
    React.SetStateAction<{
      [key: string]: number;
    }>
  >;
  setPMFeeMonthValues:  React.Dispatch<
  React.SetStateAction<{
    [key: string]: number;
  }>
  >;
  selectedRowName: string | null;
}> = ({
  monthValues,
  pmFeeMonthValues,
  handleValueChange,
  handlePMFeesValueChange,
  propertyID,
  setMonthValues,
  setPMFeeMonthValues,
  selectedRowName,
}) => {

  return (
  <>
    <CardListings
      monthValues={pmFeeMonthValues}
      handleValueChange={handlePMFeesValueChange}
      propertyID={propertyID}
      setMonthValues={setPMFeeMonthValues}
      selectedRowName={selectedRowName}
      card_label="PM Fees"
      />
    <CardListings
      monthValues={monthValues}
      handleValueChange={handleValueChange}
      propertyID={propertyID}
      setMonthValues={setMonthValues}
      selectedRowName={selectedRowName}
      card_label="Targets" 
    />
  </>

  );
};

export default TargetSettings;
