import apiClient from "./apiClient";

export const fetchRevenue = (
  page = 1,
  per_page = 10,
  selectedYear = 2024,
  searchValue = ""
) =>
  apiClient.get(
    `/api/portfolio/revenue/${selectedYear}?page=${page}&per_page=${per_page}&search=${searchValue}`
  );

  export const fetchADR = (
    page = 1,
    per_page = 10,
    selectedYear = 2024,
    searchValue = ""
  ) =>
    apiClient.get(
      `/api/portfolio/adr/${selectedYear}?page=${page}&per_page=${per_page}&search=${searchValue}`
    );

export const fetchAvailbilityAPI = (
    page = 1,
    per_page = 10,
    selectedYear = 2024,
    searchValue = ""
    ) =>
    apiClient.get(
        `/api/portfolio/availability/${selectedYear}?page=${page}&per_page=${per_page}&search=${searchValue}`
    );