import { useState } from "react";

const useSearchPagination = (
  initialSearch: string,
  itemsPerPage: number,
) => {
  const [data, setData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>(initialSearch);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(itemsPerPage);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  return {
    data,
    setData,
    searchValue,
    setSearchValue,
    currentPageNumber,
    setCurrentPageNumber,
    totalItems,
    isLoading,
    currentPageSize,
    setCurrentPageSize,
    setTotalItems,
    setIsLoading,
  };
};

export default useSearchPagination;
