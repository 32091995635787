import React from "react";
import { Segmented } from "antd";
import "tailwindcss/tailwind.css";

interface MonthYearToggleButtonProps {
  Choices: { value: string; label: string }[];
  selection?: string;
  defaultValue?: string;
  setSelection?: React.Dispatch<React.SetStateAction<string>>;
  segmentBgColor?: string; // Background color for segments
  segmentTextColor?: string; // Text color for segments
  selectedSegmentBgColor?: string; // Background color for the selected segment
  [x: string]: any;
}

const MonthYearToggleButton: React.FC<MonthYearToggleButtonProps> = ({
  Choices,
  selection,
  defaultValue,
  setSelection,
  segmentBgColor = "bg-gray-100", // Default segment background color
  segmentTextColor = "text-black", // Default segment text color
  selectedSegmentBgColor = "#4B5563", // Default selected segment background
  ...rest
}) => {
  return (
    <div className="flex justify-center">
      <Segmented
        options={Choices.map((item) => ({
          label: item.label,
          value: item.value,
        }))}
        defaultValue={defaultValue}
        value={defaultValue ? undefined : selection}
        onChange={(value) => setSelection && setSelection(value as string)}
        className={`h-fit font-semibold border border-gray-200 ${segmentBgColor} ${segmentTextColor}`}
        {...rest}
        style={{
          backgroundColor: segmentBgColor,
        }} // Apply background color to the whole segmented component
      />
      <style>
        {`

          
          .ant-segmented-item-selected {
            background-color: ${selectedSegmentBgColor} !important;
            color: white !important; /* Ensure text color is white for contrast */
          }
          .ant-segmented-item {
            background-color: ${segmentBgColor};
            color: ${segmentTextColor};
          }

          .ant-segmented-thumb {
            background-color: ${selectedSegmentBgColor} !important;
            color: white !important;
          }

          .ant-segmented-item.ant-segmented-item-selected{
            background-color: ${selectedSegmentBgColor} !important;
            color: white !important;
          }
        `}
      </style>
    </div>
  );
};

export default MonthYearToggleButton;
