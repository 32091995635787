import { useState } from "react";
import Button from "@mui/material/Button";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  fetchExportData,
  ReservationParams,
} from "../../../services/bookingAPI";
import { ExportReservationType } from "./types";

const ExportButton = ({
  channels,
  listings,
  selectedDate,
  selection,
}: ExportReservationType) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleExport = async () => {
    try {
      setIsLoading(true);
      const params: ReservationParams = {
        channel: channels.join(","),
        listing: listings.join(","),
        arrival_date_after: selectedDate
          ? selection === "month"
            ? selectedDate.startOf("month").format("YYYY-MM-DD")
            : selectedDate.startOf("year").format("YYYY-MM-DD")
          : null,
        arrival_date_before: selectedDate
          ? selection === "month"
            ? selectedDate.endOf("month").format("YYYY-MM-DD")
            : selectedDate.endOf("year").format("YYYY-MM-DD")
          : null,
      };
      const response = await fetchExportData({ params });
      const data = response.reservations;

      const csvData = convertToCSV(data);

      const blob = new Blob([csvData], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `reservations${selectedDate ? "-" + selectedDate?.toISOString() : ""}.csv`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const convertToCSV = (data: any[]) => {
    if (!data || data.length === 0) {
      return "";
    }

    const keys = Object.keys(data[0]);
    const csvRows = [];

    csvRows.push(keys.join(","));

    data.forEach((item) => {
      const values = keys.map((key) => {
        const escapeValue = String(item[key]).replace(/"/g, '""');
        return `"${escapeValue}"`;
      });
      csvRows.push(values.join(","));
    });

    return csvRows.join("\n");
  };

  return (
    <Button
      size="small"
      variant="outlined"
      startIcon={<GetAppIcon />}
      onClick={handleExport}
      disabled={isLoading}
      className="!bg-white"
    >
      Export
    </Button>
  );
};

export default ExportButton;
