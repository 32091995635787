import { useEffect, useCallback, useState } from "react";
import ReusablePagination from "../../../components/PaginationComponent";

import ToggleButton from "../../../components/ToggleButton";
import { CHOICES, ITEMS_PER_PAGE } from "../utils";
import MonthPicker from "../../../components/MonthPicker";
import FilterDropdown from "../../../components/FilterDropdown";
import CustomLightTooltip from "../../../components/CustomLightTooltip";
import IconButton from "@mui/material/IconButton";
import useFilters from "../../../hooks/customFilters";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Container, Typography } from "@mui/material";
import { BOOKING_COLUMNS } from "./constants";
import {
  fetchReservationList,
  ReservationParams,
  fetchDropdownValues,
} from "../../../services/bookingAPI";
import { Table } from "antd";
import useSearchPagination from "../../../hooks/useSearchPaginationFetch";
import { DefaultOptionType } from "antd/es/select";
import ExportButton from "./ExportReservations";
import PageHeader from "../../../components/PageHeader";

const Booking = () => {
  const { selection, selectedDate, setSelection, setSelectedDate } =
    useFilters();

  const {
    currentPageNumber,
    setCurrentPageNumber,
    totalItems,
    isLoading,
    currentPageSize,
    setCurrentPageSize,
    setTotalItems,
    setIsLoading,
  } = useSearchPagination("", ITEMS_PER_PAGE);

  const [reservations, setReservations] = useState<any>([]);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [channels, setChannels] = useState<string[]>([]);
  const [channelValues, setChannelValues] = useState<DefaultOptionType[]>([]);
  const [listings, setListings] = useState<string[]>([]);
  const [listingValues, setListingValues] = useState<DefaultOptionType[]>([]);

  const fetchDropdownLoad = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await fetchDropdownValues();
      const distinct_listings = response?.data?.distinct_listings || [];
      const distinct_channels = response?.data?.distinct_channels || [];
      const listingsValues = distinct_listings.map((val: string) => ({
        label: val,
        value: val,
      }));
      const channelsValues = distinct_channels.map((val: string) => ({
        label: val,
        value: val,
      }));

      setListingValues(listingsValues);
      setChannelValues(channelsValues);
    } catch (error) {
      console.error("Failed to fetch dropdown values:", error);
      setListingValues([]);
      setChannelValues([]);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading]);

  const fetchReservations = useCallback(async () => {
    try {
      setIsLoading(true);
      const params: ReservationParams = {
        channel: channels.join(","),
        listing: listings.join(","),
        arrival_date_after: selectedDate
          ? selection === "month"
            ? selectedDate.startOf("month").format("YYYY-MM-DD")
            : selectedDate.startOf("year").format("YYYY-MM-DD") // Start of the selected year
          : null,
          arrival_date_before: selectedDate
          ? selection === "month"
            ? selectedDate.endOf("month").format("YYYY-MM-DD")
            : selectedDate.endOf("year").format("YYYY-MM-DD")
          : null,
        page: currentPageNumber,
        per_page: currentPageSize,
      };

      // Fetch reservations from API
      const response = await fetchReservationList({
        params,
      });
      setReservations(response?.results);
      setTotalItems(response?.count);
    } catch (error) {
      console.error("Failed to fetch reservations:", error);
    } finally {
      setIsLoading(false);
    }
  }, [
    channels,
    listings,
    selectedDate,
    currentPageNumber,
    currentPageSize,
    selection,
    setIsLoading,
  ]);

  const resetFilters = () => {
    setSelection("month");
    setSelectedDate(null);
    setChannels([]);
    setListings([]);
  };

  useEffect(() => {
    fetchReservations();
  }, [currentPageNumber]);

  useEffect(() => {
    if (!isInitialLoad) {
      setCurrentPageNumber(1);
      currentPageNumber === 1 && fetchReservations();
    } else {
      fetchDropdownLoad();
      setIsInitialLoad(false);
    }
  }, [channels, listings, selectedDate, currentPageSize, selection]);

  return (
    <Container className="p-4 flex flex-col space-y-4 bg-gray-100 min-h-screen min-w-full !mx-0">
      <div className="flex flex-row items-center justify-between">
        <PageHeader text="Bookings" />

        <div className="flex flex-row gap-2 justify-end">
          <ExportButton
            channels={channels}
            listings={listings}
            selectedDate={selectedDate}
            selection={selection}
          />
        </div>
      </div>
      {/* <hr className="bg-gray-300 pt-0" /> */}
      <div className="flex inline-flex w-full justify-between space-x-4 items-center bg-white px-5 py-2 mt-3 rounded-lg shadow-lg">
        <div className="flex flex-row w-full gap-4">
          <div className="w-1/2">
            <FilterDropdown
              options={channelValues}
              placeholder="Select Channels"
              selectedValues={channels}
              setSelectedValues={setChannels}
            />
          </div>
          <div className="w-1/2 h-full">
            <FilterDropdown
              options={listingValues}
              placeholder="Select Listings"
              selectedValues={listings}
              setSelectedValues={setListings}
            />
          </div>
        </div>
        <div className="flex flex-row w-full justify-end items-center gap-4">
          <ToggleButton
            selection={selection}
            setSelection={setSelection}
            Choices={CHOICES}
          />
          <div className="w-36 pl-1">
            <MonthPicker
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              isMonthly={selection === "month"}
            />
          </div>

          <CustomLightTooltip title="Reset Filters">
            <IconButton
              onClick={resetFilters}
              className="!bg-white"
              aria-label="reset"
            >
              <RestartAltIcon />
            </IconButton>
          </CustomLightTooltip>
        </div>
      </div>

      <div className="flex flex-col space-y-4 rounded-lg shadow-lg">
        <Table
          columns={BOOKING_COLUMNS}
          dataSource={reservations}
          rowKey="id"
          bordered
          className={`font-semibold`}
          pagination={false}
          loading={isLoading}
        />
        <ReusablePagination
          currentPage={currentPageNumber}
          onPageSizeChange={setCurrentPageSize}
          pageSize={currentPageSize}
          totalItems={totalItems}
          onPageChange={setCurrentPageNumber}
        />
      </div>
    </Container>
  );
};

export default Booking;
